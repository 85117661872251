import { invokeApi } from "../../bl_libs/invokeApi";

export const add_food_list_api = async (data) => {
  const requestObj = {
    path: `/api/food`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_food_list_api = async (data, id) => {
  const requestObj = {
    path: `/api/food/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_food_list_api = async (page, rows, data, path) => {
  const requestObj = {
    path: `/api/food/get_food?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_food_api = async (id) => {
  const requestObj = {
    path: `/api/food/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const import_food_data = async (data) => {
  const requestObj = {
    path: `/api/food/import_food_data`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_active_food_list_api = async (data) => {
  const requestObj = {
    path: `/api/food/get_active_food`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

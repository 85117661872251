import React from "react";
import Chart from "react-apexcharts";

const ForeCastChart = ({ data, userPre }) => {
  const options = {
    chart: {
      type: "area",
      toolbar: { show: false },
      zoom: { enabled: false },
      pan: { enabled: false },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.4,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    dataLabels: { enabled: false },
    stroke: { curve: "smooth", width: 2 },
    xaxis: {
      categories: [
        `Latest (${userPre?.weight_unit})`,
        `Target (${userPre?.weight_unit})`,
      ],
    },
    colors: ["#026670"],
  };

  const series = [
    {
      name: "Weight",
      data: data ? data : [],
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="area" height={300} />
    </div>
  );
};

export default ForeCastChart;

import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Typography,
  Link,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { get_active_food_list_api } from "src/DAL/Food/Food";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { getValidMealArray } from "src/utils/constant";

let units_object = {
  selected_food: null,
  measurement_units: null,
  quantity: 1,
};

let meal_object = {
  meal_name: "",
  meal_type: "breakfast",
  meal_food_array: [units_object],
  meal_food_array_alt: [],
};

const FoodMattersAndMindsetUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  mainindex,
  practiceType,
  inputs_meals,
  mealArray,
  setMealArray,
  targrtDay,
  setTargrtDay,
  state,
}) => {
  const [foodList, setFoodList] = useState([]);
  const [totalCalories, setTotalCalories] = useState(0);
  const [serchText, setSerchText] = useState("");
  const [totalKcal, setTotalKcal] = useState({});

  let is_enable = state?.created_from === "program_workout";

  const params = useParams();
  const get_active_food_list = async (search) => {
    let postData = {
      search_taxt: search ? search : "",
    };
    let result = await get_active_food_list_api(postData);
    if (result.code === 200) {
      let food_list = result.food.map((item) => {
        return {
          ...item,
          measurement_units:
            item?.measurement_units.length > 0 &&
            item?.measurement_units.map((unit) => {
              return {
                ...unit,
                measurement_unit_name:
                  unit.measurement_unit_name + " (" + unit.type + ")",
              };
            }),
        };
      });
      setFoodList(food_list);
    }
  };

  const storedCalories = localStorage.getItem("day_calories");
  let parsedCalories = null;
  if (storedCalories) {
    parsedCalories = JSON.parse(storedCalories);
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeDays = (e, index) => {
    const { name, value } = e.target;
    setTargrtDay(value);
  };

  const handleChangeDes = (value) => {
    const list = [...addArray];
    list[mainindex]["description"] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUnits = (name, value, index, mealindex) => {
    let temp_state = [...mealArray];
    let temp_meal = { ...temp_state[mealindex] };
    let temp_food_array = [...temp_meal.meal_food_array];

    let temp_food_item = { ...temp_food_array[index] };
    temp_food_item[name] = value;

    temp_food_array[index] = temp_food_item;
    temp_meal.meal_food_array = temp_food_array;

    temp_state[mealindex] = temp_meal;
    if (name === "selected_food") {
      temp_meal.meal_food_array[index]["measurement_units"] = null;
      temp_meal.meal_food_array[index]["quantity"] = 1;
    }
    setSerchText("");
    setMealArray(temp_state);
  };

  const handleChangeMeal = (name, index, value) => {
    let temp_state = [...mealArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setMealArray(temp_state);
  };

  const handleChangeUnitsAlt = (name, value, index, mealindex) => {
    let temp_state = [...mealArray];
    let temp_meal = { ...temp_state[mealindex] };
    let temp_food_array = [...temp_meal.meal_food_array_alt];

    let temp_food_item = { ...temp_food_array[index] };
    temp_food_item[name] = value;

    temp_food_array[index] = temp_food_item;
    temp_meal.meal_food_array_alt = temp_food_array;

    temp_state[mealindex] = temp_meal;

    if (name === "selected_food") {
      temp_meal.meal_food_array_alt[index]["measurement_units"] = null;
      temp_meal.meal_food_array_alt[index]["quantity"] = 1;
    }
    setSerchText("");
    setMealArray(temp_state);
  };

  const handleAddUnit = (mealindex) => {
    if (!is_enable) {
      setMealArray((prevState) => {
        let tempState = [...prevState];
        let tempMeal = { ...tempState[mealindex] };
        tempMeal.meal_food_array = [...tempMeal.meal_food_array, units_object];
        tempState[mealindex] = tempMeal;
        return tempState;
      });
    }
  };

  const handleRemoveUnit = (unitIndex, mealIndex) => {
    if (!is_enable) {
      setMealArray((prevState) => {
        let tempState = [...prevState];
        let tempMeal = { ...tempState[mealIndex] };
        tempMeal.meal_food_array.splice(unitIndex, 1);
        tempState[mealIndex] = tempMeal;
        return tempState;
      });
    }
  };

  const handleAddMeal = () => {
    if (!is_enable) {
      setMealArray((old) =>
        Array.isArray(old) ? [...old, meal_object] : [meal_object]
      );
    }
  };

  const handleRemoveMeal = (index) => {
    if (!is_enable) {
      const updatedItems = [...mealArray];
      updatedItems.splice(index, 1);
      setMealArray(updatedItems);
    }
  };

  const convertToPerGram = (value = 0, quantity = 1) => {
    if (isNaN(value) || isNaN(quantity)) return 0;
    return parseFloat(((value / 100) * quantity).toFixed(2));
  };

  const handleAddUnitAlt = (mealindex) => {
    if (!is_enable) {
      setMealArray((prevState) => {
        let tempState = [...prevState];
        let tempMeal = { ...tempState[mealindex] };
        tempMeal.meal_food_array_alt = [
          ...tempMeal.meal_food_array_alt,
          units_object,
        ];
        tempState[mealindex] = tempMeal;
        return tempState;
      });
    }
  };

  const handleRemoveUnitAlt = (unitIndex, mealIndex) => {
    if (!is_enable) {
      setMealArray((prevState) => {
        let tempState = [...prevState];
        let tempMeal = { ...tempState[mealIndex] };
        tempMeal.meal_food_array_alt.splice(unitIndex, 1);
        tempState[mealIndex] = tempMeal;
        return tempState;
      });
    }
  };

  const getTotalCalorie = (carb = 0, protein = 0, fat = 0) => {
    carb = Number(carb) || 0;
    protein = Number(protein) || 0;
    fat = Number(fat) || 0;

    return carb * 4 + protein * 4 + fat * 9;
  };

  useEffect(() => {
    if (practiceType == "food") {
      get_active_food_list(serchText);
    }
  }, [practiceType, serchText]);

  useEffect(() => {
    if (parsedCalories) {
      if (targrtDay == "regular") {
        setTotalCalories(parsedCalories?.regular_day_calories);
      } else if (targrtDay == "high") {
        if (parsedCalories?.kcal_type == "macro") {
          const total_calorie = getTotalCalorie(
            parsedCalories?.high_day_carb,
            parsedCalories?.high_day_protein,
            parsedCalories?.high_day_fat
          );
          setTotalKcal(parsedCalories);

          setTotalCalories(total_calorie);
        } else {
          setTotalCalories(parsedCalories?.high_day_calories);
        }
      } else if (targrtDay == "low") {
        if (parsedCalories?.kcal_type == "macro") {
          const total_calorie = getTotalCalorie(
            parsedCalories?.low_day_carb,
            parsedCalories?.low_day_protein,
            parsedCalories?.low_day_fat
          );
          setTotalKcal(parsedCalories);
          setTotalCalories(total_calorie);
        } else {
          setTotalCalories(parsedCalories?.low_day_calories);
        }
      }
    }
  }, [targrtDay]);

  useEffect(() => {
    inputs_meals.meals = mealArray;
  }, [mealArray]);

  const getTotalNutrient = (meals, nutrient, type) => {
    if (!meals && !Array.isArray(meals)) return "0.00";

    return meals
      .reduce((total, meal) => {
        if (!Array.isArray(meal[type])) return total;
        return (
          total +
          meal[type].reduce((mealTotal, food) => {
            const value = food?.measurement_units?.[nutrient] || 0;
            const quantity = parseFloat(food?.quantity) || 0;
            return mealTotal + value * (quantity / 100);
          }, 0)
        );
      }, 0)
      .toFixed(2);
  };

  const getTotalCalories = (meals, type) => {
    const totalCarbs = parseFloat(getTotalNutrient(meals, "carb", type));
    const totalProtein = parseFloat(getTotalNutrient(meals, "protein", type));
    const totalFat = parseFloat(getTotalNutrient(meals, "fat", type));

    return (totalCarbs * 4 + totalProtein * 4 + totalFat * 9).toFixed(2);
  };

  const calculateTotal = (meal, name) => {
    if (!meal || !Array.isArray(meal)) {
      return "0.00";
    }

    const total = meal.reduce((sum, item) => {
      const nutrientValue = item?.measurement_units?.[name] || 0;
      const quantity = parseFloat(item?.quantity) || 0;
      return sum + nutrientValue * (quantity / 100);
    }, 0);

    return total.toFixed(2);
  };

  const calculateTotalCalories = (meals) => {
    const totalCarbs = parseFloat(calculateTotal(meals, "carb"));
    const totalProtein = parseFloat(calculateTotal(meals, "protein"));
    const totalFat = parseFloat(calculateTotal(meals, "fat"));

    const totalCalories = totalCarbs * 4 + totalProtein * 4 + totalFat * 9;

    return totalCalories.toFixed(2);
  };

  let update_array = mealArray;

  const getQuantity = (qun, type) => {
    let quantity = 0;

    if (parsedCalories?.regular_day_calories) {
      quantity =
        (parsedCalories?.regular_day_calories /
          getTotalCalories(update_array, type)) *
        qun;
    } else {
      return qun;
    }
    return quantity.toFixed(2);
  };

  if (params?.id && state?.created_from === "program_workout") {
    if (update_array && update_array.length > 0) {
      let data = update_array.map((meal) => {
        return {
          ...meal,
          meal_food_array:
            meal?.meal_food_array &&
            meal?.meal_food_array.length > 0 &&
            meal?.meal_food_array?.map((unit) => {
              return {
                ...unit,
                quantity: getQuantity(unit.quantity, "meal_food_array"),
              };
            }),
          meal_food_array_alt:
            meal?.meal_food_array_alt &&
            meal?.meal_food_array_alt.length > 0 &&
            meal?.meal_food_array_alt?.map((unit) => {
              return {
                ...unit,
                quantity: getQuantity(unit.quantity, "meal_food_array_alt"),
              };
            }),
        };
      });
      update_array = data;
    }
  }

  let remaining_kcal =
    totalCalories -
    parseFloat(getTotalCalories(update_array, "meal_food_array"));

  let total_carb = 0;
  let total_fat = 0;
  let total_pro = 0;

  let rem_total_carb = 0;
  let rem_total_fat = 0;
  let rem_total_pro = 0;

  if (totalKcal?.kcal_type == "macro") {
    if (targrtDay == "high") {
      total_carb = totalKcal.high_day_carb;
      total_fat = totalKcal.high_day_fat;
      total_pro = totalKcal.high_day_protein;

      rem_total_carb =
        totalKcal.high_day_carb -
        parseFloat(getTotalNutrient(update_array, "carb", "meal_food_array"));
      rem_total_fat =
        totalKcal.high_day_fat -
        parseFloat(getTotalNutrient(update_array, "fat", "meal_food_array"));
      rem_total_pro =
        totalKcal.high_day_protein -
        parseFloat(
          getTotalNutrient(update_array, "protein", "meal_food_array")
        );
    } else {
      total_carb = totalKcal.low_day_carb;
      total_fat = totalKcal.low_day_fat;
      total_pro = totalKcal.low_day_protein;

      rem_total_carb =
        totalKcal.low_day_carb -
        parseFloat(getTotalNutrient(update_array, "carb", "meal_food_array"));
      rem_total_fat =
        totalKcal.low_day_fat -
        parseFloat(getTotalNutrient(update_array, "fat", "meal_food_array"));
      rem_total_pro =
        totalKcal.low_day_protein -
        parseFloat(
          getTotalNutrient(update_array, "protein", "meal_food_array")
        );
    }
  }

  return (
    <div className="my-2">
      <div className="mt-2 ">
        <div className="characters">
          <form onSubmit={() => {}}>
            <div
              style={{
                width: "100%",
                padding: "1px",
                position: "relative",
              }}
            >
              <div
                className=" my-1 workout-set-card w-100"
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div className="row align-items-center">
                    <div className={`col-12 col-md-6 mt-2 mt-md-0`}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Title"
                        required
                        value={
                          addArray[mainindex]?.title
                            ? addArray[mainindex]?.title
                            : ""
                        }
                        name="title"
                        onChange={(e) => handleChange(e, mainindex)}
                        InputLabelProps={{
                          className: "textfiels-input-label",
                          shrink: true,
                          style: { fontSize: "14px" },
                        }}
                        label="Title"
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "13px",
                          },
                        }}
                        sx={{
                          borderRadius: "5px",
                          fontSize: "13px",
                          width: "100%",
                        }}
                      />
                    </div>
                    {practiceType == "food" && (
                      <>
                        {params?.id && !parsedCalories?.regular_day_calories ? (
                          <div className="col-12 mt-4">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                opacity: 0.6,
                                fontWeight: "600",
                                fontSize: "12px",
                              }}
                            >
                              Please{" "}
                              <Link
                                component={RouterLink}
                                variant="subtitle2"
                                to={`/member/edit-member/${params?.id}`}
                              >
                                Click here
                              </Link>{" "}
                              to enter member details (Gender, Weight, Height,
                              Age, and Activity) to calculate their Regular Day
                              calorie needs for food portioning
                            </Typography>
                          </div>
                        ) : (
                          <>
                            <div className={`col-12 col-md-6 mt-3 mt-md-0`}>
                              <FormControl
                                fullWidth
                                required
                                readOnly={is_enable}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Target Day
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="days"
                                  readOnly={!params?.id || is_enable}
                                  value={targrtDay}
                                  label="Target Day"
                                  onChange={handleChangeDays}
                                  IconComponent={
                                    !params?.id || is_enable
                                      ? () => null
                                      : undefined
                                  }
                                >
                                  <MenuItem value="regular">
                                    Regular Day
                                  </MenuItem>
                                  <MenuItem value="high">High Day</MenuItem>
                                  <MenuItem value="low">Low Day</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-12 d-flex justify-content-between align-items-center flex-wrap mt-4">
                              <h3>Add Meal</h3>
                              <div
                                onClick={handleAddMeal}
                                style={{ cursor: "pointer" }}
                              >
                                <AddCircleOutlineIcon className="duplicate-icon" />{" "}
                                Add New Meal
                              </div>
                            </div>
                            {is_enable && (
                              <div className="col-12">
                                <p className="mb-0 text-muted">
                                  This Meal is added from the General Programme,
                                  meal-related fields cannot be edited here. To
                                  make changes, please update it directly in the
                                  General Programme.
                                </p>
                              </div>
                            )}
                            {parsedCalories?.deficit_percentage &&
                              parsedCalories?.deficit_value && (
                                <div className="col-12">
                                  <p className="mb-0 text-muted">
                                    For this member, the calorie deficit is
                                    active at {parsedCalories?.deficit_value}%,
                                    and food portions will be adjusted
                                    accordingly for the member on the Client
                                    Portal.
                                  </p>
                                </div>
                              )}

                            {update_array && update_array?.length > 0 && (
                              <>
                                <div className="col-12">
                                  {update_array.map((meal, mealindex) => {
                                    return (
                                      <div
                                        className="row mt-3 food_meal_card position-relative mx-1"
                                        key={mealindex}
                                      >
                                        <div
                                          onClick={() => {
                                            handleRemoveMeal(mealindex);
                                          }}
                                          className="fix_icon_remove_food"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Tooltip
                                            title="Remove Meal"
                                            placement="top"
                                          >
                                            <RemoveCircleOutlineIcon
                                              style={{ fill: "red" }}
                                              className="duplicate-icon"
                                            />
                                          </Tooltip>
                                        </div>
                                        <div className="col-12 col-md-4">
                                          <TextField
                                            id="outlined-basic"
                                            label="Meal Name"
                                            variant="outlined"
                                            InputProps={{
                                              readOnly: is_enable,
                                            }}
                                            fullWidth
                                            size="small"
                                            required
                                            name="meal_name"
                                            value={meal.meal_name}
                                            onChange={(e) => {
                                              handleChangeMeal(
                                                "meal_name",
                                                mealindex,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 col-md-4 mt-3 mt-md-0">
                                          <FormControl
                                            fullWidth
                                            required
                                            readOnly={is_enable}
                                          >
                                            <InputLabel id="demo-simple-select-label">
                                              Meal Type
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              name="meal_type"
                                              size="small"
                                              value={meal.meal_type}
                                              readOnly={is_enable}
                                              label="Meal Type"
                                              onChange={(e) => {
                                                handleChangeMeal(
                                                  "meal_type",
                                                  mealindex,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <MenuItem value="pre">
                                                Pre Workout
                                              </MenuItem>
                                              <MenuItem value="post">
                                                Post Workout
                                              </MenuItem>
                                              <MenuItem value="snack">
                                                Snack
                                              </MenuItem>
                                              <MenuItem value="breakfast">
                                                Breakfast
                                              </MenuItem>
                                              <MenuItem value="lunch">
                                                Lunch
                                              </MenuItem>
                                              <MenuItem value="dinner">
                                                Dinner
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        <div className="col-12 col-md-4 d-flex justify-content-end">
                                          {meal?.meal_food_array_alt?.length ==
                                            0 &&
                                            meal?.meal_food_array[0]
                                              ?.selected_food && (
                                              <div
                                                className="mb-2 me-3 mt-3"
                                                onClick={() => {
                                                  handleAddUnitAlt(mealindex);
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                  flexWrap: "nowrap",
                                                }}
                                              >
                                                <AddCircleOutlineIcon className="duplicate-icon" />{" "}
                                                Add Alternative Meal
                                              </div>
                                            )}
                                        </div>
                                        {meal?.meal_food_array &&
                                          meal?.meal_food_array?.length > 0 && (
                                            <div className="col-12">
                                              {meal?.meal_food_array?.map(
                                                (units, index) => {
                                                  return (
                                                    <div className="row align-items-center px-0 mt-3">
                                                      <div className="col-12 col-md-3">
                                                        <MUIAutocomplete
                                                          inputLabel="Select Food"
                                                          selectedOption={
                                                            units.selected_food
                                                          }
                                                          setSelectedOption={(
                                                            e
                                                          ) => {
                                                            handleChangeUnits(
                                                              "selected_food",
                                                              e,
                                                              index,
                                                              mealindex
                                                            );
                                                          }}
                                                          required
                                                          readOnly={is_enable}
                                                          setCustomSearch={
                                                            setSerchText
                                                          }
                                                          optionsList={foodList}
                                                          autoComplete="new-password"
                                                          name="name"
                                                          size="small"
                                                        />
                                                      </div>
                                                      {units?.selected_food
                                                        ?.measurement_units &&
                                                        units?.selected_food
                                                          ?.measurement_units
                                                          ?.length > 0 && (
                                                          <div className="col-12 col-md-2 mt-3 mt-md-0">
                                                            <MUIAutocomplete
                                                              inputLabel="Select Unit"
                                                              selectedOption={
                                                                units.measurement_units
                                                              }
                                                              readOnly={
                                                                is_enable
                                                              }
                                                              setSelectedOption={(
                                                                e
                                                              ) => {
                                                                handleChangeUnits(
                                                                  "measurement_units",
                                                                  e,
                                                                  index,
                                                                  mealindex
                                                                );
                                                              }}
                                                              required
                                                              optionsList={
                                                                units
                                                                  ?.selected_food
                                                                  ?.measurement_units
                                                              }
                                                              autoComplete="new-password"
                                                              name="measurement_unit_name"
                                                              size="small"
                                                            />
                                                          </div>
                                                        )}
                                                      {units?.selected_food &&
                                                        units.measurement_units && (
                                                          <div className="col-12 col-md-2 mt-3 mt-md-0">
                                                            <TextField
                                                              id="outlined-basic"
                                                              label="Food weight in grams"
                                                              variant="outlined"
                                                              fullWidth
                                                              size="small"
                                                              InputProps={{
                                                                readOnly:
                                                                  is_enable,
                                                              }}
                                                              type="number"
                                                              required
                                                              name="quantity"
                                                              onChange={(e) => {
                                                                handleChangeUnits(
                                                                  "quantity",
                                                                  e.target
                                                                    .value,
                                                                  index,
                                                                  mealindex
                                                                );
                                                              }}
                                                              value={
                                                                units.quantity
                                                              }
                                                            />
                                                          </div>
                                                        )}
                                                      {units.quantity > 0 &&
                                                        units?.selected_food &&
                                                        units.measurement_units && (
                                                          <div className="col-12 col-md-4 mt-3 mt-md-0 food_inputs_container">
                                                            Carb:{" "}
                                                            <input
                                                              value={convertToPerGram(
                                                                units
                                                                  .measurement_units
                                                                  .carb,
                                                                units.quantity
                                                              )}
                                                              className="food_inputs mx-2 mt-3 mt-md-0"
                                                              readOnly
                                                            />
                                                            Protein:{" "}
                                                            <input
                                                              value={convertToPerGram(
                                                                units
                                                                  .measurement_units
                                                                  .protein,
                                                                units.quantity
                                                              )}
                                                              className="food_inputs mx-2 mt-3 mt-md-0"
                                                              readOnly
                                                            />
                                                            Fat:{" "}
                                                            <input
                                                              value={convertToPerGram(
                                                                units
                                                                  .measurement_units
                                                                  .fat,
                                                                units.quantity
                                                              )}
                                                              className="food_inputs mx-2 mt-3 mt-md-0"
                                                              readOnly
                                                            />
                                                          </div>
                                                        )}
                                                      <div className="col-1 d-flex d-md-block mt-3 mt-md-0">
                                                        <AddCircleOutlineIcon
                                                          className="duplicate-icon me-2 "
                                                          onClick={() => {
                                                            handleAddUnit(
                                                              mealindex
                                                            );
                                                          }}
                                                        />
                                                        {meal?.meal_food_array
                                                          ?.length !== 1 && (
                                                          <RemoveCircleOutlineIcon
                                                            style={{
                                                              fill: "red",
                                                            }}
                                                            className="duplicate-icon"
                                                            onClick={() =>
                                                              handleRemoveUnit(
                                                                index,
                                                                mealindex
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                              <div className="text-end mt-1 mx-3">
                                                Meal Calories{" "}
                                                {calculateTotalCalories(
                                                  meal?.meal_food_array
                                                )}
                                              </div>
                                              {meal?.meal_food_array_alt
                                                .length > 0 && (
                                                <>
                                                  <div
                                                    className="text-center my-3"
                                                    style={{
                                                      color:
                                                        "var(--portal-main-color)",
                                                    }}
                                                  >
                                                    Alternative Meal
                                                  </div>
                                                  {meal?.meal_food_array_alt.map(
                                                    (units, index) => {
                                                      return (
                                                        <div
                                                          className={`row align-items-center px-0 ${
                                                            index ===
                                                            meal
                                                              ?.meal_food_array_alt
                                                              .length -
                                                              1
                                                              ? ""
                                                              : "mb-3"
                                                          }`}
                                                        >
                                                          <div className="col-12 col-md-3">
                                                            <MUIAutocomplete
                                                              inputLabel="Select Food"
                                                              selectedOption={
                                                                units.selected_food
                                                              }
                                                              required
                                                              readOnly={
                                                                is_enable
                                                              }
                                                              setSelectedOption={(
                                                                e
                                                              ) => {
                                                                handleChangeUnitsAlt(
                                                                  "selected_food",
                                                                  e,
                                                                  index,
                                                                  mealindex
                                                                );
                                                              }}
                                                              setCustomSearch={
                                                                setSerchText
                                                              }
                                                              optionsList={
                                                                foodList
                                                              }
                                                              autoComplete="new-password"
                                                              name="name"
                                                              size="small"
                                                            />
                                                          </div>
                                                          {units?.selected_food
                                                            ?.measurement_units &&
                                                            units?.selected_food
                                                              ?.measurement_units
                                                              ?.length > 0 && (
                                                              <div className="col-12 col-md-2 mt-3 mt-md-0">
                                                                <MUIAutocomplete
                                                                  inputLabel="Select Unit"
                                                                  selectedOption={
                                                                    units.measurement_units
                                                                  }
                                                                  setSelectedOption={(
                                                                    e
                                                                  ) => {
                                                                    handleChangeUnitsAlt(
                                                                      "measurement_units",
                                                                      e,
                                                                      index,
                                                                      mealindex
                                                                    );
                                                                  }}
                                                                  optionsList={
                                                                    units
                                                                      ?.selected_food
                                                                      ?.measurement_units
                                                                  }
                                                                  autoComplete="new-password"
                                                                  name="measurement_unit_name"
                                                                  size="small"
                                                                  readOnly={
                                                                    is_enable
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                          {units?.selected_food &&
                                                            units.measurement_units && (
                                                              <div className="col-12 col-md-2 mt-3 mt-md-0">
                                                                <TextField
                                                                  id="outlined-basic"
                                                                  label="Food weight in grams"
                                                                  variant="outlined"
                                                                  fullWidth
                                                                  size="small"
                                                                  type="number"
                                                                  required
                                                                  name="quantity"
                                                                  InputProps={{
                                                                    readOnly:
                                                                      is_enable,
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleChangeUnitsAlt(
                                                                      "quantity",
                                                                      e.target
                                                                        .value,
                                                                      index,
                                                                      mealindex
                                                                    );
                                                                  }}
                                                                  value={
                                                                    units.quantity
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                          {units.quantity > 0 &&
                                                            units?.selected_food &&
                                                            units.measurement_units && (
                                                              <div className="col-12 col-md-4 mt-3 mt-md-0">
                                                                Carb:{" "}
                                                                <input
                                                                  value={convertToPerGram(
                                                                    units
                                                                      .measurement_units
                                                                      .carb,
                                                                    units.quantity
                                                                  )}
                                                                  className="food_inputs mx-2"
                                                                  readOnly
                                                                />
                                                                Protein:{" "}
                                                                <input
                                                                  value={convertToPerGram(
                                                                    units
                                                                      .measurement_units
                                                                      .protein,
                                                                    units.quantity
                                                                  )}
                                                                  className="food_inputs mx-2"
                                                                  readOnly
                                                                />
                                                                Fat:{" "}
                                                                <input
                                                                  value={convertToPerGram(
                                                                    units
                                                                      .measurement_units
                                                                      .fat,
                                                                    units.quantity
                                                                  )}
                                                                  className="food_inputs mx-2"
                                                                  readOnly
                                                                />
                                                              </div>
                                                            )}
                                                          <div className="col-1 mt-3 mt-md-0 d-flex d-md-block">
                                                            <AddCircleOutlineIcon
                                                              className="duplicate-icon me-2 "
                                                              onClick={() => {
                                                                handleAddUnitAlt(
                                                                  mealindex
                                                                );
                                                              }}
                                                            />
                                                            <RemoveCircleOutlineIcon
                                                              style={{
                                                                fill: "red",
                                                              }}
                                                              className="duplicate-icon"
                                                              onClick={() =>
                                                                handleRemoveUnitAlt(
                                                                  index,
                                                                  mealindex
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                  <div className="text-end mt-1 mx-3">
                                                    Alternative Meal Calories{" "}
                                                    {calculateTotalCalories(
                                                      meal?.meal_food_array_alt
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                                {update_array &&
                                  update_array.length > 0 &&
                                  update_array[0]?.meal_food_array &&
                                  update_array[0]?.meal_food_array.length > 0 &&
                                  update_array[0]?.meal_food_array[0]
                                    ?.selected_food && (
                                    <div className="col-12 col-md-4 ms-auto mt-3">
                                      <div className="food_meal_card px-3">
                                        {params?.id && (
                                          <>
                                            <p className="mb-1 d-flex align-items-center justify-content-between">
                                              <span className="fw-bold">
                                                Your Daily Goal:&nbsp;
                                              </span>
                                              {Number(
                                                totalCalories || 0
                                              ).toFixed(2)}{" "}
                                              kcal
                                            </p>
                                            {totalKcal.kcal_type == "macro" &&
                                              targrtDay !== "regular" && (
                                                <>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Daily Carb:&nbsp;
                                                    </span>
                                                    {total_carb}
                                                  </p>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Daily Protein:&nbsp;
                                                    </span>
                                                    {total_pro}
                                                  </p>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Daily Fat:&nbsp;
                                                    </span>
                                                    {total_fat}
                                                  </p>
                                                  <hr />
                                                </>
                                              )}
                                          </>
                                        )}

                                        <p className=" mb-1 d-flex align-items-center justify-content-between">
                                          <span className="fw-bold">
                                            Total Calories:&nbsp;
                                          </span>
                                          {getTotalCalories(
                                            update_array,
                                            "meal_food_array"
                                          )}
                                          kcal
                                        </p>
                                        <p className="mb-1 text-muted d-flex align-items-center justify-content-between">
                                          <span>Carb:&nbsp;</span>
                                          {parseFloat(
                                            getTotalNutrient(
                                              update_array,
                                              "carb",
                                              "meal_food_array"
                                            )
                                          )}
                                        </p>
                                        <p className="mb-1 text-muted d-flex align-items-center justify-content-between">
                                          <span>Protein:&nbsp;</span>
                                          {parseFloat(
                                            getTotalNutrient(
                                              update_array,
                                              "protein",
                                              "meal_food_array"
                                            )
                                          )}
                                        </p>
                                        <p className="mb-1 text-muted d-flex align-items-center justify-content-between">
                                          <span>Fat:&nbsp;</span>
                                          {parseFloat(
                                            getTotalNutrient(
                                              update_array,
                                              "fat",
                                              "meal_food_array"
                                            )
                                          )}
                                        </p>
                                        {params?.id && (
                                          <>
                                            <hr />
                                            <p className="mb-1 d-flex align-items-center justify-content-between">
                                              <span className="fw-bold">
                                                Remaining:&nbsp;
                                              </span>
                                              {remaining_kcal
                                                ? remaining_kcal.toFixed(2)
                                                : "0.00"}{" "}
                                              kcal
                                            </p>
                                            {totalKcal.kcal_type == "macro" &&
                                              targrtDay !== "regular" && (
                                                <>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Remaining Carb:&nbsp;
                                                    </span>
                                                    {rem_total_carb}
                                                  </p>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Remaining Protein:&nbsp;
                                                    </span>
                                                    {rem_total_pro}
                                                  </p>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Remaining Fat:&nbsp;
                                                    </span>
                                                    {rem_total_fat}
                                                  </p>
                                                </>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <div className="col-12 mt-3">
                      <h4>Detail Description</h4>
                      <TinyEditor
                        setDetailDescription={handleChangeDes}
                        detailDescriptionCk={addArray[mainindex]?.description}
                      />
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default FoodMattersAndMindsetUI;

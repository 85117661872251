import React, { useEffect, useState } from "react";
import { Stack, Chip, Typography, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import moment from "moment";
import { useParams } from "react-router-dom";

const ScheduleWorkouts = ({
  props,
  programme,
  selectedDay,
  setSelectedDay,
  setSelectedDate,
  selectedDate,
  setIsPastedWorkouts,
}) => {
  const [tableData, setTableData] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const { id } = useParams();

  const handleCellHover = (rowIndex, colIndex) => {
    setSelectedCells((prev) => {
      const cell = { rowIndex, colIndex };
      const exists = prev.some(
        (c) => c.rowIndex === rowIndex && c.colIndex === colIndex
      );

      return exists
        ? prev.filter((c) => c.rowIndex !== rowIndex || c.colIndex !== colIndex)
        : [...prev, cell];
    });
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    setSelectedDate((prevDates) => {
      if (!prevDates.includes(formattedDate)) {
        return [...prevDates, formattedDate];
      }
      return prevDates;
    });
  };

  const handleRemoveDate = (date) => {
    setSelectedDate((prevDates) => {
      const updatedDates = prevDates.filter((d) => d !== date);
      return [...updatedDates];
    });
  };

  useEffect(() => {
    if (programme) {
      if (programme?.program_length_type === "limited") {
        const weeks = programme.no_of_weeks || 0;
        setTableData(Array(weeks).fill(Array(7).fill("")));
      }
    }
  }, [programme]);

  useEffect(() => {
    if (selectedCells.length > 0) {
      let selectedDays = selectedCells.map((cell) => {
        let week = cell.rowIndex + 1;
        let days = cell.colIndex + 1;
        return (week - 1) * 7 + days;
      });

      setSelectedDay(selectedDays);
    }
  }, [selectedCells]);

  useEffect(() => {
    if (selectedDay.length > 0 && selectedCells.length === 0) {
      let newSelectedCells = selectedDay.map((dayNumber) => {
        let week = Math.floor((dayNumber - 1) / 7);
        let day = (dayNumber - 1) % 7;
        return { rowIndex: week, colIndex: day };
      });

      setSelectedCells(newSelectedCells);
    }
  }, [selectedDay]);

  return (
    <div className="row">
      <p className="text-muted">
        Use this feature to add a single workout across multiple days. Simply
        select the dates on the calendar where you want this workout to appear.
        Once saved, the same workout will be added to all the selected dates.
      </p>
      {programme?.program_length_type === "unlimited" || id ? (
        <>
          <div className="col-12 col-md-6 mt-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                onChange={handleDateChange}
                slots={{
                  day: (props) => {
                    const formattedDate = dayjs(props.day).format("YYYY-MM-DD");
                    const isSelected = selectedDate.includes(formattedDate);
                    return (
                      <PickersDay
                        {...props}
                        selected={isSelected}
                        sx={{
                          backgroundColor: isSelected
                            ? "#04abbb"
                            : "transparent",
                          color: isSelected ? "#fff" : "inherit",
                          borderRadius: "50%",
                          "&:hover": {
                            backgroundColor: isSelected ? "#04abbb" : "#e0e0e0",
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
              {[...selectedDate]
                .sort((a, b) => new Date(a) - new Date(b))
                .map((date, index) => (
                  <Chip
                    style={{
                      marginRight: 5,
                      marginTop: 10,
                      backgroundColor: "var(--portal-theme-secondary)",
                    }}
                    key={index}
                    label={moment(date).format("DD-MM-YYYY")}
                    onDelete={() => handleRemoveDate(date)}
                  />
                ))}
            </Stack>
          </div>
        </>
      ) : (
        <div className="col-lg-12 mt-3">
          <table className="fixedTable" id="dragTable">
            <thead>
              <tr>
                {[...Array(7)].map((_, index) => {
                  return (
                    <th className="fixed-table-head" key={index}>
                      <div className="mt-2 mb-2" style={{ opacity: 0.7 }}>
                        Day {index + 1}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr key={rowIndex} className="">
                  {row.map((col, colIndex) => {
                    const isSelected = selectedCells.some(
                      (c) => c.rowIndex === rowIndex && c.colIndex === colIndex
                    );

                    return (
                      <td
                        className={`fixed-table-data hover_index ${
                          isSelected ? "selected_cell" : ""
                        }`}
                        key={colIndex}
                        onClick={() => handleCellHover(rowIndex, colIndex)}
                      >
                        <div
                          style={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                            marginTop: 15,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "600",
                              ml: 0.5,
                              mt: 2,
                              opacity: 0.6,
                            }}
                            variant="body2"
                          >
                            Week {rowIndex + 1}
                          </Typography>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div
        className="col-12 mt-3 text-end"
        onClick={() => {
          setIsPastedWorkouts(false);
        }}
      >
        <Button variant="contained" color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};

export default ScheduleWorkouts;

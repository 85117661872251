import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { get_weight_list_api } from "src/DAL/member/member";
import { makeStyles } from "@mui/styles";
import WeightWaistChart from "./WeightWaistChart";
import moment from "moment";
import ForeCastChart from "./ForeCastChart";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const WeightTracker = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const [timePeriod, setTimePeriod] = useState("7_day");
  const [isLoading, setIsLoading] = useState(true);
  const [userPre, setUserPre] = useState({});
  const [chartList, setChartList] = useState([]);
  const [dataList, setDataList] = useState(null);

  const weight_list = async () => {
    setIsLoading(true);
    const result = await get_weight_list_api(timePeriod, id);
    if (result.code === 200) {
      setUserPre(result.user_preferences);
      setChartList(result.graph_weights);
      setDataList(result);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    weight_list();
  }, [timePeriod]);

  let current_weight = "";

  if (userPre?.weight_unit == "kg") {
    current_weight = dataList?.current_weight_in_kg;
  } else {
    current_weight = dataList?.current_weight_in_lbs;
  }

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="row mt-2">
      <div className="col-12 d-flex justify-content-between">
        <div className="d-flex">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="me-3">Weight Tracker</h2>
        </div>
      </div>
      <div
        className={
          current_weight && dataList?.target_weight && dataList?.predictedDate
            ? "col-12 col-md-7 mt-3"
            : "col-md-12"
        }
      >
        <div className="card p-4 border-0 d-flex flex-column h-100 shoping_card">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div></div>
            <>
              <Box sx={{ width: 150 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Filter By
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setTimePeriod(e.target.value)}
                    value={timePeriod}
                    label="Filter By"
                  >
                    <MenuItem value="7_day">7 Days</MenuItem>
                    <MenuItem value="30_day">30 Days</MenuItem>
                    <MenuItem value="90_day">90 Days</MenuItem>
                    <MenuItem value="1_year">1 Year</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          </div>

          <WeightWaistChart
            height={385}
            chartList={chartList}
            userPre={userPre}
          />
        </div>
      </div>
      {current_weight && dataList?.target_weight && dataList?.predictedDate ? (
        <div className="col-12 col-md-5 mt-3">
          <div className="card p-4 border-0 d-flex flex-column h-100 shoping_card">
            <h3>Target Weight Achievement Forecast</h3>
            <p className="text-black text-muted">
              Based on member's current progress, they are expected to reach
              their target weight by{" "}
              {moment(dataList?.predictedDate).format("DD-MMMM-YYYY")}.
            </p>
            <div className="d-flex justify-content-center">
              <p className="text-black fw-bold">
                Member Target Weight: {dataList?.target_weight}{" "}
                {userPre?.weight_unit}
              </p>
            </div>
            <div className="mt-3">
              <ForeCastChart
                data={[current_weight, dataList?.target_weight]}
                userPre={userPre}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default WeightTracker;

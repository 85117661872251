import {
  CircularProgress,
  TextField,
  Tooltip,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useParams } from "react-router-dom";
import { getValidMealArray } from "src/utils/constant";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const AnswerdFoodMattersAndMindsetUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  mainindex,
  message,
  meals_data,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCalories, setTotalCalories] = useState(0);
  const [totalKcal, setTotalKcal] = useState({});
  const params = useParams();
  const [isHovered, setIsHovered] = useState("");

  const classes = useStyles();

  // Function to update intervalTime state
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      time_interval: 60,
      rounds: 10,
      type: "general",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const convertToPerGram = (value = 0, quantity = 1) => {
    if (isNaN(value) || isNaN(quantity)) return 0;
    return parseFloat(((value / 100) * quantity).toFixed(2));
  };

  const getTotalNutrient = (meals, nutrient, type) => {
    if (!meals && !Array.isArray(meals)) return "0.00";

    return meals
      .reduce((total, meal) => {
        if (!Array.isArray(meal[type])) return total;
        return (
          total +
          meal[type].reduce((mealTotal, food) => {
            const value = food?.measurement_units?.[nutrient] || 0;
            const quantity = parseFloat(food?.quantity) || 0;
            return mealTotal + value * (quantity / 100);
          }, 0)
        );
      }, 0)
      .toFixed(2);
  };

  const getTotalCalories = (meals, type) => {
    const totalCarbs = parseFloat(getTotalNutrient(meals, "carb", type));
    const totalProtein = parseFloat(getTotalNutrient(meals, "protein", type));
    const totalFat = parseFloat(getTotalNutrient(meals, "fat", type));

    return (totalCarbs * 4 + totalProtein * 4 + totalFat * 9).toFixed(2);
  };
  const calculateTotal = (meal, name) => {
    if (!meal || !Array.isArray(meal)) {
      return "0.00";
    }

    const total = meal.reduce((sum, item) => {
      const nutrientValue = item?.measurement_units?.[name] || 0;
      const quantity = parseFloat(item?.quantity) || 0;
      return sum + nutrientValue * (quantity / 100);
    }, 0);

    return total.toFixed(2);
  };

  const calculateTotalCalories = (meals) => {
    const totalCarbs = parseFloat(calculateTotal(meals, "carb"));
    const totalProtein = parseFloat(calculateTotal(meals, "protein"));
    const totalFat = parseFloat(calculateTotal(meals, "fat"));

    const totalCalories = totalCarbs * 4 + totalProtein * 4 + totalFat * 9;

    return totalCalories.toFixed(2);
  };

  let mealArray = getValidMealArray(meals_data.editData?.meals);

  const storedCalories = localStorage.getItem("day_calories");

  const getQuantity = (qun, type) => {
    let quantity = 0;
    const parsedCalories = JSON.parse(storedCalories);
    if (parsedCalories?.regular_day_calories) {
      quantity =
        (parsedCalories?.regular_day_calories /
          getTotalCalories(mealArray, type)) *
        qun;
    } else {
      return qun;
    }
    return quantity.toFixed(2);
  };

  if (meals_data?.editData?.created_from === "program_workout") {
    if (mealArray && mealArray.length > 0) {
      let data = mealArray.map((meal) => {
        return {
          ...meal,
          meal_food_array:
            meal?.meal_food_array &&
            meal?.meal_food_array.length > 0 &&
            meal?.meal_food_array?.map((unit) => {
              return {
                ...unit,
                quantity: getQuantity(unit.quantity, "meal_food_array"),
              };
            }),
          meal_food_array_alt:
            meal?.meal_food_array_alt &&
            meal?.meal_food_array_alt.length > 0 &&
            meal?.meal_food_array_alt?.map((unit) => {
              return {
                ...unit,
                quantity: getQuantity(unit.quantity, "meal_food_array_alt"),
              };
            }),
        };
      });
      mealArray = data;
    }
  }

  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };

  const getTotalCalorie = (carb = 0, protein = 0, fat = 0) => {
    carb = Number(carb) || 0;
    protein = Number(protein) || 0;
    fat = Number(fat) || 0;

    return carb * 4 + protein * 4 + fat * 9;
  };

  useEffect(() => {
    const storedCalories = localStorage.getItem("day_calories");
    if (storedCalories) {
      const parsedCalories = JSON.parse(storedCalories);
      if (meals_data?.editData?.target_day == "regular") {
        setTotalCalories(parsedCalories?.regular_day_calories);
      } else if (meals_data?.editData?.target_day == "high") {
        if (parsedCalories?.kcal_type == "macro") {
          const total_calorie = getTotalCalorie(
            parsedCalories?.high_day_carb,
            parsedCalories?.high_day_protein,
            parsedCalories?.high_day_fat
          );
          setTotalKcal(parsedCalories);
          setTotalCalories(total_calorie);
        } else {
          setTotalCalories(parsedCalories?.high_day_calories);
        }
      } else if (meals_data?.editData?.target_day == "low") {
        if (parsedCalories?.kcal_type == "macro") {
          const total_calorie = getTotalCalorie(
            parsedCalories?.low_day_carb,
            parsedCalories?.low_day_protein,
            parsedCalories?.low_day_fat
          );
          setTotalKcal(parsedCalories);
          setTotalCalories(total_calorie);
        } else {
          setTotalCalories(parsedCalories?.low_day_calories);
        }
      }
    }
  }, [meals_data?.editData?.target_day]);

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let remaining_kcal =
    totalCalories - parseFloat(getTotalCalories(mealArray, "meal_food_array"));

  let total_carb = 0;
  let total_fat = 0;
  let total_pro = 0;

  let rem_total_carb = 0;
  let rem_total_fat = 0;
  let rem_total_pro = 0;

  if (totalKcal?.kcal_type == "macro") {
    if (targrtDay == "high") {
      total_carb = totalKcal.high_day_carb;
      total_fat = totalKcal.high_day_fat;
      total_pro = totalKcal.high_day_protein;

      rem_total_carb =
        totalKcal.high_day_carb -
        parseFloat(getTotalNutrient(update_array, "carb", "meal_food_array"));
      rem_total_fat =
        totalKcal.high_day_fat -
        parseFloat(getTotalNutrient(update_array, "fat", "meal_food_array"));
      rem_total_pro =
        totalKcal.high_day_protein -
        parseFloat(
          getTotalNutrient(update_array, "protein", "meal_food_array")
        );
    } else {
      total_carb = totalKcal.low_day_carb;
      total_fat = totalKcal.low_day_fat;
      total_pro = totalKcal.low_day_protein;

      rem_total_carb =
        totalKcal.low_day_carb -
        parseFloat(getTotalNutrient(update_array, "carb", "meal_food_array"));
      rem_total_fat =
        totalKcal.low_day_fat -
        parseFloat(getTotalNutrient(update_array, "fat", "meal_food_array"));
      rem_total_pro =
        totalKcal.low_day_protein -
        parseFloat(
          getTotalNutrient(update_array, "protein", "meal_food_array")
        );
    }
  }

  return (
    <div className="my-2">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}

      <div className="mt-2">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{
                width: "100%",
                padding: "1px",
                position: "relative",
              }}
            >
              <div
                // direction="row"

                className="d-flex my-1 workout-set-card w-100"
                onMouseEnter={() => handleMouseLeave()}
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                  onMouseEnter={() => handleMouseLeave()}
                >
                  <div className="row " onMouseEnter={() => handleMouseLeave()}>
                    <div
                      className={`col-sm-12 ${"col-md-6"} col-lg-6 d-flex align-items-center`}
                    >
                      {addArray[mainindex].is_modified && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-11px",
                            justifyContent: "center",
                            zIndex: "1",
                            left: "-38px",
                            transform: "rotate(-53deg)",
                          }}
                        >
                          <Chip
                            label={"Modified"}
                            variant="contained"
                            color={"error"}
                            sx={{
                              fontSize: "10px",
                              height: "auto",
                              width: "auto",
                            }}
                          />
                        </span>
                      )}
                      <Tooltip placement="top" title={message}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Name"
                          value={
                            addArray[mainindex].title
                              ? addArray[mainindex].title
                              : ""
                          }
                          name="title"
                          onChange={(e) => handleChangeDes(e, mainindex)}
                          InputLabelProps={{
                            className: "textfiels-input-label",
                            shrink: true,
                            style: { fontSize: "14px" },
                          }}
                          multiline
                          rows={addArray[mainindex].title ? null : 1}
                          label="Name"
                          InputProps={{
                            className: "textfiels-input-value",
                            style: {
                              fontSize: "13px",
                              paddingLeft: "6px",
                            },

                            inputProps: {
                              readOnly: true,
                            },
                          }}
                          sx={{
                            borderRadius: "5px",

                            fontSize: "13px",
                            width: "100%",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <Tooltip placement="top" title={message}>
                      {meals_data?.editData?.practice_type == "food" && (
                        <>
                          <div className={`col-12 col-md-6 mt-3 mt-md-0`}>
                            <FormControl fullWidth required readOnly>
                              <InputLabel id="demo-simple-select-label">
                                Target Day
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="days"
                                readOnly
                                value={
                                  meals_data?.editData?.target_day || "regular"
                                }
                                label="Target Day"
                                IconComponent={
                                  !params?.id ? () => null : undefined
                                }
                              >
                                <MenuItem value="regular">Regular Day</MenuItem>
                                <MenuItem value="high">High Day</MenuItem>
                                <MenuItem value="low">Low Day</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          {mealArray && mealArray?.length > 0 && (
                            <>
                              <Tooltip placement="top" title={message}>
                                <div className="col-12">
                                  {mealArray.map((meal, mealindex) => {
                                    return (
                                      <div
                                        className="row mt-3 food_meal_card position-relative mx-1"
                                        key={mealindex}
                                      >
                                        <div className="col-12 col-md-4">
                                          <TextField
                                            id="outlined-basic"
                                            label="Meal Name"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            required
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            name="meal_name"
                                            value={meal.meal_name}
                                          />
                                        </div>
                                        <div className="col-12 col-md-4 mt-3 mt-md-0">
                                          <FormControl
                                            fullWidth
                                            required
                                            readOnly
                                          >
                                            <InputLabel id="demo-simple-select-label">
                                              Meal Type
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              name="meal_type"
                                              size="small"
                                              value={meal.meal_type}
                                              label="Meal Type"
                                              readOnly
                                            >
                                              <MenuItem value="pre">
                                                Pre Workout
                                              </MenuItem>
                                              <MenuItem value="post">
                                                Post Workout
                                              </MenuItem>
                                              <MenuItem value="snack">
                                                Snack
                                              </MenuItem>
                                              <MenuItem value="breakfast">
                                                Breakfast
                                              </MenuItem>
                                              <MenuItem value="lunch">
                                                Lunch
                                              </MenuItem>
                                              <MenuItem value="dinner">
                                                Dinner
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        {meal?.meal_food_array &&
                                          meal?.meal_food_array?.length > 0 && (
                                            <div className="col-12">
                                              {meal?.meal_food_array?.map(
                                                (units, index) => {
                                                  return (
                                                    <div className="row align-items-center px-0 mt-3">
                                                      <div className="col-12 col-md-3">
                                                        <MUIAutocomplete
                                                          inputLabel="Select Food"
                                                          selectedOption={
                                                            units.selected_food
                                                          }
                                                          readOnly={true}
                                                          required
                                                          setSelectedOption={() => {}}
                                                          optionsList={[]}
                                                          autoComplete="new-password"
                                                          name="name"
                                                          size="small"
                                                        />
                                                      </div>
                                                      {units?.selected_food
                                                        ?.measurement_units &&
                                                        units?.selected_food
                                                          ?.measurement_units
                                                          ?.length > 0 && (
                                                          <div className="col-12 col-md-2 mt-3 mt-md-0">
                                                            <MUIAutocomplete
                                                              inputLabel="Select Unit"
                                                              selectedOption={
                                                                units.measurement_units
                                                              }
                                                              setSelectedOption={() => {}}
                                                              required
                                                              optionsList={
                                                                units
                                                                  ?.selected_food
                                                                  ?.measurement_units
                                                              }
                                                              autoComplete="new-password"
                                                              name="measurement_unit_name"
                                                              size="small"
                                                              readOnly={true}
                                                            />
                                                          </div>
                                                        )}
                                                      {units?.selected_food &&
                                                        units.measurement_units && (
                                                          <div className="col-12 col-md-2 mt-3 mt-md-0">
                                                            <TextField
                                                              id="outlined-basic"
                                                              label="Food weight in grams"
                                                              variant="outlined"
                                                              fullWidth
                                                              size="small"
                                                              type="number"
                                                              required
                                                              name="quantity"
                                                              InputProps={{
                                                                readOnly: true,
                                                              }}
                                                              value={
                                                                units.quantity
                                                              }
                                                            />
                                                          </div>
                                                        )}
                                                      {units.quantity > 0 &&
                                                        units?.selected_food &&
                                                        units.measurement_units && (
                                                          <div className="col-12 col-md-4 mt-3 mt-md-0 food_inputs_container">
                                                            Carb:{" "}
                                                            <input
                                                              value={convertToPerGram(
                                                                units
                                                                  .measurement_units
                                                                  .carb,
                                                                units.quantity
                                                              )}
                                                              className="food_inputs mx-2 mt-3 mt-md-0"
                                                              readOnly
                                                            />
                                                            Protein:{" "}
                                                            <input
                                                              value={convertToPerGram(
                                                                units
                                                                  .measurement_units
                                                                  .protein,
                                                                units.quantity
                                                              )}
                                                              className="food_inputs mx-2 mt-3 mt-md-0"
                                                              readOnly
                                                            />
                                                            Fat:{" "}
                                                            <input
                                                              value={convertToPerGram(
                                                                units
                                                                  .measurement_units
                                                                  .fat,
                                                                units.quantity
                                                              )}
                                                              className="food_inputs mx-2 mt-3 mt-md-0"
                                                              readOnly
                                                            />
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                              <div className="text-end mt-1 mx-3">
                                                Meal Calories{" "}
                                                {calculateTotalCalories(
                                                  meal?.meal_food_array
                                                )}
                                              </div>
                                              {meal?.meal_food_array_alt
                                                .length > 0 && (
                                                <>
                                                  <div
                                                    className="text-center my-3"
                                                    style={{
                                                      color:
                                                        "var(--portal-main-color)",
                                                    }}
                                                  >
                                                    Alternative Meal
                                                  </div>
                                                  {meal?.meal_food_array_alt.map(
                                                    (units, index) => {
                                                      return (
                                                        <div
                                                          className={`row align-items-center px-0 ${
                                                            index ===
                                                            meal
                                                              ?.meal_food_array_alt
                                                              .length -
                                                              1
                                                              ? ""
                                                              : "mb-3"
                                                          }`}
                                                        >
                                                          <div className="col-12 col-md-3">
                                                            <MUIAutocomplete
                                                              inputLabel="Select Food"
                                                              selectedOption={
                                                                units.selected_food
                                                              }
                                                              required
                                                              setSelectedOption={() => {}}
                                                              optionsList={[]}
                                                              readOnly={true}
                                                              autoComplete="new-password"
                                                              name="name"
                                                              size="small"
                                                            />
                                                          </div>
                                                          {units?.selected_food
                                                            ?.measurement_units &&
                                                            units?.selected_food
                                                              ?.measurement_units
                                                              ?.length > 0 && (
                                                              <div className="col-12 col-md-2 mt-3 mt-md-0">
                                                                <MUIAutocomplete
                                                                  inputLabel="Select Unit"
                                                                  selectedOption={
                                                                    units.measurement_units
                                                                  }
                                                                  setSelectedOption={() => {}}
                                                                  optionsList={[]}
                                                                  readOnly={
                                                                    true
                                                                  }
                                                                  autoComplete="new-password"
                                                                  name="measurement_unit_name"
                                                                  size="small"
                                                                />
                                                              </div>
                                                            )}
                                                          {units?.selected_food &&
                                                            units.measurement_units && (
                                                              <div className="col-12 col-md-2 mt-3 mt-md-0">
                                                                <TextField
                                                                  id="outlined-basic"
                                                                  label="Food weight in grams"
                                                                  variant="outlined"
                                                                  fullWidth
                                                                  size="small"
                                                                  type="number"
                                                                  required
                                                                  name="quantity"
                                                                  InputProps={{
                                                                    readOnly: true,
                                                                  }}
                                                                  value={
                                                                    units.quantity
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                          {units.quantity > 0 &&
                                                            units?.selected_food &&
                                                            units.measurement_units && (
                                                              <div className="col-12 col-md-4 mt-3 mt-md-0">
                                                                Carb:{" "}
                                                                <input
                                                                  value={convertToPerGram(
                                                                    units
                                                                      .measurement_units
                                                                      .carb,
                                                                    units.quantity
                                                                  )}
                                                                  className="food_inputs mx-2"
                                                                  readOnly
                                                                />
                                                                Protein:{" "}
                                                                <input
                                                                  value={convertToPerGram(
                                                                    units
                                                                      .measurement_units
                                                                      .protein,
                                                                    units.quantity
                                                                  )}
                                                                  className="food_inputs mx-2"
                                                                  readOnly
                                                                />
                                                                Fat:{" "}
                                                                <input
                                                                  value={convertToPerGram(
                                                                    units
                                                                      .measurement_units
                                                                      .fat,
                                                                    units.quantity
                                                                  )}
                                                                  className="food_inputs mx-2"
                                                                  readOnly
                                                                />
                                                              </div>
                                                            )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                  <div className="text-end mt-1 mx-3">
                                                    Alternative Meal Calories{" "}
                                                    {calculateTotalCalories(
                                                      meal?.meal_food_array_alt
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </Tooltip>
                              <Tooltip placement="top" title={message}>
                                {mealArray &&
                                  mealArray.length > 0 &&
                                  mealArray[0]?.meal_food_array &&
                                  mealArray[0]?.meal_food_array.length > 0 &&
                                  mealArray[0]?.meal_food_array[0]
                                    ?.selected_food && (
                                    <div className="col-12 col-md-4 ms-auto mt-3">
                                      <div className="food_meal_card px-3">
                                        {params?.id && (
                                          <>
                                            <p className="mb-1 d-flex align-items-center justify-content-between">
                                              <span className="fw-bold">
                                                Your Daily Goal:&nbsp;
                                              </span>
                                              {Number(
                                                totalCalories || 0
                                              ).toFixed(2)}{" "}
                                              kcal
                                            </p>
                                            {totalKcal.kcal_type == "macro" &&
                                              targrtDay !== "regular" && (
                                                <>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Daily Carb:&nbsp;
                                                    </span>
                                                    {total_carb}
                                                  </p>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Daily Protein:&nbsp;
                                                    </span>
                                                    {total_pro}
                                                  </p>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Daily Fat:&nbsp;
                                                    </span>
                                                    {total_fat}
                                                  </p>
                                                  <hr />
                                                </>
                                              )}
                                          </>
                                        )}
                                        <p className="mb-1 d-flex align-items-center justify-content-between">
                                          <span className="fw-bold">
                                            Total Calories:&nbsp;
                                          </span>
                                          {getTotalCalories(
                                            mealArray,
                                            "meal_food_array"
                                          )}{" "}
                                          kcal
                                        </p>
                                        <p className="mb-1 text-muted d-flex align-items-center justify-content-between">
                                          <span>Carb&nbsp;</span>
                                          {parseFloat(
                                            getTotalNutrient(
                                              mealArray,
                                              "carb",
                                              "meal_food_array"
                                            )
                                          )}
                                        </p>
                                        <p className="mb-1 text-muted d-flex align-items-center justify-content-between">
                                          <span>Protein&nbsp;</span>
                                          {parseFloat(
                                            getTotalNutrient(
                                              mealArray,
                                              "protein",
                                              "meal_food_array"
                                            )
                                          )}
                                        </p>
                                        <p className="mb-1 text-muted d-flex align-items-center justify-content-between">
                                          <span>Fat&nbsp;</span>
                                          {parseFloat(
                                            getTotalNutrient(
                                              mealArray,
                                              "fat",
                                              "meal_food_array"
                                            )
                                          )}
                                        </p>
                                        {params?.id && (
                                          <>
                                            <hr />
                                            <p className="mb-1 d-flex align-items-center justify-content-between">
                                              <span className="fw-bold">
                                                Remaining:&nbsp;
                                              </span>
                                              {remaining_kcal
                                                ? remaining_kcal.toFixed(2)
                                                : "0.00"}{" "}
                                              kcal
                                            </p>
                                            {totalKcal.kcal_type == "macro" &&
                                              targrtDay !== "regular" && (
                                                <>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Remaining Carb:&nbsp;
                                                    </span>
                                                    {rem_total_carb}
                                                  </p>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Remaining Protein:&nbsp;
                                                    </span>
                                                    {rem_total_pro}
                                                  </p>
                                                  <p className="text-muted mb-1 d-flex align-items-center justify-content-between">
                                                    <span>
                                                      Remaining Fat:&nbsp;
                                                    </span>
                                                    {rem_total_fat}
                                                  </p>
                                                </>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </Tooltip>
                            </>
                          )}
                        </>
                      )}
                    </Tooltip>
                  </div>
                  <Tooltip placement="top" title={message}>
                    <div className=" mt-4">
                      <div
                        className="exercise-label-box"
                        style={{ minHeight: "90px" }}
                      >
                        <span className="outer-label">Description</span>
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: addArray[mainindex].description,
                            }}
                          ></div>
                        </p>
                      </div>
                    </div>
                  </Tooltip>
                </Box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AnswerdFoodMattersAndMindsetUI;

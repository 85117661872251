import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TimeZones } from "src/utils/constant";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Divider,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { countries } from "src/utils/country";
import { AddMemberApi } from "src/DAL/member/member";
import { coachListing } from "src/DAL/coach/coach";
import { getAllTemplatesListByType } from "src/DAL/FormTemplate/feedbackTemplates";
import { DatePicker } from "@mui/lab";
import { addDays } from "date-fns";
import { useEffect } from "react";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddMember() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [country, setCountry] = useState({
    code: "IE",
    label: "Ireland",
    phone: "353",
  });

  const [coachValue, setCoachValue] = useState([]);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputValue, setInputValue] = useState("");
  const [allTemplates, setAllTemplates] = useState([]);
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    goal_status: "false",
    member_status: "true",
    street: "",
    street2: "",
    postalStreet: "",
    postalStreet2: "",
    postalZip_code: "",
    postalState: "",
    postalCity: "",
    city: "",
    zip_code: "",
    state: "",
    coach_id: "",
    first_payment_date: new Date(),
    member_biography: "",
    image: "",
    short_description: "",
    time_zone: "",
    memberShipPurchase: "false",
    is_executive: "false",
    isRecurring: false,
    recurringDays: 1,
    form_template: null,
    recurring_end_date: null,
    high_day_calories: "",
    low_day_calories: "",
    regular_day_calories: "",
    gender: "male",
    weight: "",
    height: "",
    age: "",
    activity_level: "sedentary",
    deficit_percentage: true,
    deficit_value: "",
    days_for_deficit: "",
    kcal_type: "kcal",
    high_day_carb: "",
    high_day_protein: "",
    high_day_fat: "",
    low_day_carb: "",
    low_day_protein: "",
    low_day_fat: "",
    last_cycle_date: null,
  });

  const consultantListData = async () => {
    setIsLoading(true);
    let result = await coachListing();
    if (result.code == 200) {
      setCoachValue(result.coach_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const calculateTDEE = (value) => {
    const { gender, weight, height, age, activity_level } = value;

    if (!gender || !weight || !height || !age || !activity_level) {
      return 0;
    }

    let BMR;

    if (gender === "male") {
      BMR = 10 * weight + 6.25 * height - 5 * age + 5;
    } else if (gender === "female") {
      BMR = 10 * weight + 6.25 * height - 5 * age - 161;
    } else {
      BMR = 10 * weight + 6.25 * height - 5 * age - 78;
    }

    const activityMultipliers = {
      sedentary: 1.2,
      lightly: 1.375,
      moderately: 1.55,
      active: 1.725,
      super: 1.9,
    };

    const TDEE = BMR * (activityMultipliers[activity_level] || 1.2);

    return Math.round(TDEE);
  };

  let totalKcal = calculateTDEE(inputs);

  const getFormListing = async () => {
    try {
      const result = await getAllTemplatesListByType("member");
      if (result.code === 200) {
        setAllTemplates(result?.form_templates);
        setInputs((values) => ({ ...values, form_template: "" }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };

  const handleChangeTemplate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (country == "" || country == undefined) {
      enqueueSnackbar("Country is required", {
        variant: "error",
      });
    } else {
      if (inputs.days < 0) {
        enqueueSnackbar("No of days must be positive number ", {
          variant: "error",
        });
      } else if (inputs?.isRecurring && inputs.recurringDays < 1) {
        enqueueSnackbar("No of Recurring days at least 1", {
          variant: "error",
        });
        return;
      } else if (!inputs.image) {
        enqueueSnackbar("Upload image", {
          variant: "error",
        });
      } else if (inputs?.isRecurring && !inputs.form_template) {
        enqueueSnackbar(`Please select a Form Template`, {
          variant: "error",
        });
        return;
      } else if (!inputs.image) {
        enqueueSnackbar("Upload image", {
          variant: "error",
        });
      } else if (inputs.days_for_deficit > 30) {
        enqueueSnackbar("Deficit Duration must be between 1-30", {
          variant: "error",
        });
      } else if (inputs.deficit_value > 30) {
        enqueueSnackbar("Deficit Percentage must be between 1-30", {
          variant: "error",
        });
      } else {
        const formData = new FormData();
        formData.append("first_name", inputs.first_name);
        formData.append("last_name", inputs.last_name);
        if (file) {
          formData.append("profile_image", inputs.image);
        }
        formData.append("member_biography", inputs.short_description);
        formData.append("email", inputs.email);
        formData.append("password", inputs.password);
        formData.append("contact_number", inputs.contact_number);
        formData.append("status", inputs.member_status);
        formData.append("street", inputs.street);
        formData.append("city", inputs.city);
        formData.append("zip_code", inputs.zip_code);
        formData.append("state", inputs.state);
        formData.append("country", country.code);
        formData.append("coach_id", inputs.coach_id);
        formData.append("regular_day_calories", totalKcal);
        formData.append("high_day_calories", inputs.high_day_calories || 0);
        formData.append("low_day_calories", inputs.low_day_calories || 0);
        formData.append("gender", inputs.gender);
        formData.append("age", inputs.age);
        formData.append("weight", inputs.weight);
        formData.append("height", inputs.height);
        formData.append("activity_level", inputs.activity_level);
        formData.append("deficit_percentage", inputs.deficit_percentage);
        formData.append("deficit_value", inputs.deficit_value || 0);
        formData.append("days_for_deficit", inputs.days_for_deficit || 0);
        formData.append("high_day_carb", inputs.high_day_carb || 0);
        formData.append("high_day_fat", inputs.high_day_fat || 0);
        formData.append("high_day_protein", inputs.high_day_protein || 0);
        formData.append("low_day_carb", inputs.low_day_carb || 0);
        formData.append("low_day_protein", inputs.low_day_protein || 0);
        formData.append("low_day_fat", inputs.low_day_fat || 0);
        formData.append("kcal_type", inputs.kcal_type);
        formData.append(
          "last_cycle_date",
          inputs.gender === "female"
            ? inputs.last_cycle_date && inputs.last_cycle_date !== "null"
              ? inputs.last_cycle_date
              : ""
            : ""
        );

        if (inputs.isRecurring) {
          formData.append("recurring_end_date", inputs.recurring_end_date);
        }
        formData.append(
          "recurring_form_days",
          inputs?.isRecurring ? inputs.recurringDays : ""
        );
        formData.append(
          "form_template",
          inputs?.isRecurring ? inputs.form_template?._id : ""
        );
        formData.append("time_zone", timeZoneValue);

        setIsLoading(true);
        const result = await AddMemberApi(formData);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getTotalCalories = (carb = 0, protein = 0, fat = 0) => {
    carb = Number(carb) || 0;
    protein = Number(protein) || 0;
    fat = Number(fat) || 0;

    return carb * 4 + protein * 4 + fat * 9;
  };

  const handleSelectOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const today = new Date();
  if (inputs.days_for_deficit > 0 && inputs.days_for_deficit < 31) {
    today.setDate(today.getDate() + parseInt(inputs.days_for_deficit));
  }
  const formattedDate = today.toISOString().split("T")[0];

  useEffect(() => {
    consultantListData();
    getFormListing();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="me-3">Add Member</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              fullWidth
              required
              name="first_name"
              value={inputs.first_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              fullWidth
              name="last_name"
              value={inputs.last_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              defaultValue="Hello World"
              fullWidth
              required
              name="email"
              value={inputs.email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              fullWidth
              type="password"
              required
              name="password"
              value={inputs.password}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Contact Number"
              variant="outlined"
              fullWidth
              name="contact_number"
              value={inputs.contact_number}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Member Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="member_status"
                value={inputs.member_status}
                label="Member Status *"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Recurring Forms?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="isRecurring"
                value={inputs.isRecurring}
                label="Recurring Forms?*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.isRecurring && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-selec-label">
                    Form Template*
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-selec-label"
                    id="demo-simple-select"
                    name="form_template"
                    value={inputs?.form_template}
                    label="Form Template*"
                    onChange={handleChangeTemplate}
                  >
                    {allTemplates.map((item) => (
                      <MenuItem key={item._id} value={item}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label={`${
                    inputs.accessType == "limited"
                      ? `No Of Day (${inputs?.no_of_weeks ? 1 : 0} to ${
                          inputs?.no_of_weeks * 7
                        })`
                      : "No Of Recurring Days"
                  }`}
                  variant="outlined"
                  type="number"
                  placeholder={`${
                    inputs.accessType == "limited"
                      ? `No Of Day (${inputs?.no_of_weeks ? 1 : 0} to ${
                          inputs?.no_of_weeks * 7
                        })`
                      : "No Of Recurring Days"
                  }`}
                  fullWidth
                  required
                  name="recurringDays"
                  value={inputs.recurringDays}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DatePicker
                    label="End Date"
                    name="recurring_end_date"
                    disablePast
                    inputFormat="dd-MM-yyyy"
                    value={inputs.recurring_end_date}
                    onChange={(e) => {
                      handleSelectOther("recurring_end_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-lg-2 col-md-2 col-6">
                {file && (
                  <img className="image-border" src={file} height="50" />
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-6 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>
          <div className="col-12">
            <h3>Address Details</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="country-select-demo"
              // sx={{ width: 300 }}
              options={countries}
              autoHighlight
              value={country}
              onChange={(event, newValue) => {
                setCountry(newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    className="image-border"
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country *"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              fullWidth
              name="city"
              required
              value={inputs.city}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="State"
              variant="outlined"
              fullWidth
              name="state"
              required
              value={inputs.state}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Street Address 1"
              variant="outlined"
              fullWidth
              required
              name="street"
              value={inputs.street}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Zip Code"
              variant="outlined"
              fullWidth
              required
              name="zip_code"
              value={inputs.zip_code}
              onChange={handleChange}
            />
          </div>
          <Divider className="mt-2" />
          <h3 className="mt-3">User Info</h3>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="gender"
                value={inputs.gender}
                label="Gender"
                onChange={handleChange}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.gender === "female" && (
            <div className="col-12 col-md-4 mt-3">
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DatePicker
                  label="Last Cycle Date"
                  name="last_cycle_date"
                  inputFormat="dd-MM-yyyy"
                  value={inputs.last_cycle_date}
                  onChange={(e) => {
                    handleSelectOther("last_cycle_date", e);
                  }}
                  format="DD-MM-YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="inputs-fields"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          )}
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel htmlFor="outlined-adornment-amount">
                Weight
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={inputs?.weight}
                onChange={handleChange}
                name="weight"
                type="number"
                endAdornment={
                  <InputAdornment position="end">kg</InputAdornment>
                }
                label="Weight"
              />
            </FormControl>
          </div>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel htmlFor="outlined-adornment-amount">
                Height
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={inputs?.height}
                type="number"
                onChange={handleChange}
                name="height"
                endAdornment={
                  <InputAdornment position="end">cm</InputAdornment>
                }
                label="Height"
              />
            </FormControl>
          </div>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel htmlFor="outlined-adornment-amount">Age</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={inputs?.age}
                type="number"
                onChange={handleChange}
                name="age"
                endAdornment={
                  <InputAdornment position="end">year</InputAdornment>
                }
                label="Age"
              />
            </FormControl>
          </div>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Activity Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="activity_level"
                value={inputs.activity_level}
                label="Activity Level"
                onChange={handleChange}
              >
                <MenuItem value="sedentary">
                  Sedentary (little/no exercise)
                </MenuItem>
                <MenuItem value="lightly">
                  Lightly active (1-3 days/week exercise)
                </MenuItem>
                <MenuItem value="moderately">
                  Moderately active (3-5 days/week exercise)
                </MenuItem>
                <MenuItem value="active">
                  Very active (6-7 days/week exercise)
                </MenuItem>
                <MenuItem value="super">
                  Super active (athlete or hard labor)
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <h3 className="mt-3">Allowed Calories</h3>
          <div className="col-12 col-md-4 mt-3">
            <TextField
              id="outlined-basic"
              label="Regular Day Calories"
              variant="outlined"
              fullWidth
              required
              InputProps={{
                readOnly: true,
              }}
              type="number"
              name="regular_day_calories"
              onChange={handleChange}
              value={totalKcal}
            />
          </div>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="kcal_type"
                value={inputs.kcal_type}
                label="Type"
                onChange={handleChange}
              >
                <MenuItem value="kcal">Calories</MenuItem>
                <MenuItem value="macro">Macro Nutrients</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-md-4"></div>
          {inputs.kcal_type == "kcal" ? (
            <>
              <div className="col-12 col-md-4 mt-3">
                <TextField
                  id="outlined-basic"
                  label="High Day Calories"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="high_day_calories"
                  onChange={handleChange}
                  value={inputs.high_day_calories}
                />
              </div>
              <div className="col-12 col-md-4 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Low Day Calories"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="low_day_calories"
                  onChange={handleChange}
                  value={inputs.low_day_calories}
                />
              </div>
            </>
          ) : (
            <>
              {inputs.kcal_type == "macro" && (
                <>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="High Day Carb"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="high_day_carb"
                      onChange={handleChange}
                      value={inputs.high_day_carb}
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="High Day Protein"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="high_day_protein"
                      onChange={handleChange}
                      value={inputs.high_day_protein}
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="High Day Fat"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="high_day_fat"
                      onChange={handleChange}
                      value={inputs.high_day_fat}
                    />
                  </div>
                  <div className="col-12 text-end mt-2">
                    <p className="mb-0 text-muted fs_10">
                      High Day Calories:{" "}
                      {getTotalCalories(
                        inputs.high_day_carb,
                        inputs.high_day_protein,
                        inputs.high_day_fat
                      )}
                    </p>
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Low Day Carb"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="low_day_carb"
                      onChange={handleChange}
                      value={inputs.low_day_carb}
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Low Day Protein"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="low_day_protein"
                      onChange={handleChange}
                      value={inputs.low_day_protein}
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Low Day Fat"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="low_day_fat"
                      onChange={handleChange}
                      value={inputs.low_day_fat}
                    />
                  </div>
                  <div className="col-12 text-end mt-2">
                    <p className="mb-0 text-muted fs_10">
                      Low Day Calories:{" "}
                      {getTotalCalories(
                        inputs.low_day_carb,
                        inputs.low_day_protein,
                        inputs.low_day_fat
                      )}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
          <h3 className="mt-3">Calorie Deficit</h3>
          {inputs.deficit_percentage && (
            <p className="text-muted mb-0">
              Enter a calorie deficit percentage between 1-30% and set the
              duration between 1-30 days.
            </p>
          )}
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Allowed Calorie Deficit
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="deficit_percentage"
                value={inputs.deficit_percentage}
                label="Is Allowed Calorie Deficit"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.deficit_percentage && (
            <>
              <div className="col-12 col-md-4 mt-3">
                <FormControl fullWidth required>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Calorie Deficit Percentage
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={inputs?.deficit_value}
                    type="number"
                    onChange={handleChange}
                    name="deficit_value"
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    label="Calorie Deficit Percentage"
                  />
                </FormControl>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Calorie Deficit Duration (Days)"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="days_for_deficit"
                  onChange={handleChange}
                  value={inputs.days_for_deficit}
                />
              </div>
              {inputs.days_for_deficit > 0 && inputs.days_for_deficit < 31 && (
                <div className="col-12 text-end mt-2">
                  <p className="text-end text-muted">
                    Calorie Deficit Period: {moment().format("DD-MM-YYYY")} to{" "}
                    {moment(formattedDate).format("DD-MM-YYYY")}
                  </p>
                </div>
              )}
            </>
          )}
          <Divider className="mt-2" />
          <h3 className="mt-3">Configurations</h3>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Coach</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inputs.coach_id}
                name="coach_id"
                label="Coach"
                onChange={handleChange}
              >
                {coachValue.map((consultant) => {
                  return (
                    <MenuItem value={consultant._id}>
                      {consultant.first_name + " " + consultant.last_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              value={timeZoneValue}
              onChange={(event, newValue) => {
                setTimeZoneValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={TimeZones}
              renderInput={(params) => (
                <TextField {...params} label="Time Zone *" />
              )}
            />
          </div>
          <div className="col-12 mt-5">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description "
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
              <FormHelperText>
                Member Biography (Maximum limit 500 characters)
              </FormHelperText>
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

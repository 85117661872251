import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const WeightWaistChart = ({ height, chartList, userPre }) => {
  const [selectedMetric, setSelectedMetric] = useState("weight");
  const [chartData, setChartData] = useState({ labels: [], seriesData: {} });

  useEffect(() => {
    if (chartList && chartList.length > 0) {
      const labels = chartList.map((entry) =>
        new Date(entry.date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
        })
      );

      const seriesData = {
        weight: chartList.map((entry) => entry.current_weight),
        waist: chartList.map((entry) => entry.waist),
        chest: chartList.map((entry) => entry.chest),
        hip: chartList.map((entry) => entry.hip),
        arm: chartList.map((entry) => entry.arm),
        thigh: chartList.map((entry) => entry.thigh),
        bodyFat: chartList.map((entry) => entry.body_fat),
      };

      setChartData({ labels, seriesData });
    }
  }, [chartList]);

  const colors = {
    weight: "#026670",
    waist: "#F4D160",
    chest: "#FF5733",
    hip: "#3498DB",
    arm: "#9B59B6",
    thigh: "#2ECC71",
    bodyFat: "#E74C3C",
  };

  const options = {
    chart: {
      type: "area",
      toolbar: { show: false },
      zoom: { enabled: false },
      pan: { enabled: false },
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          const clickedMetric = Object.keys(chartData.seriesData)[seriesIndex];
          setSelectedMetric(clickedMetric);
        },
      },
    },
    colors: Object.keys(chartData.seriesData).map((metric) =>
      selectedMetric === metric ? colors[metric] : "#DDDDDD"
    ),
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.4,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    dataLabels: { enabled: false },
    stroke: { curve: "smooth", width: 2 },
    xaxis: { categories: chartData.labels },
    yaxis: {
      title: {
        text: {
          weight: `Weight (${userPre?.weight_unit})`,
          waist: `Waist (${userPre?.waist_unit})`,
          chest: `Chest (${userPre?.chest_unit})`,
          hip: `Hip (${userPre?.hip_unit})`,
          arm: `Arm (${userPre?.arm_unit})`,
          thigh: `Thigh (${userPre?.thigh_unit})`,
          bodyFat: `bodyFat (%)`,
        }[selectedMetric],
        style: { fontSize: "14px" },
      },
    },
    legend: {
      position: "top",
      onItemClick: { toggleDataSeries: false },
      onItemHover: { highlightDataSeries: true },
      formatter: function (seriesName) {
        const labelsMap = {
          weight: `Weight (${userPre?.weight_unit})`,
          waist: `Waist (${userPre?.waist_unit})`,
          chest: `Chest (${userPre?.chest_unit})`,
          hip: `Hip (${userPre?.hip_unit})`,
          arm: `Arm (${userPre?.arm_unit})`,
          thigh: `Thigh (${userPre?.thigh_unit})`,
          bodyFat: `bodyFat (%)`,
        };
        return labelsMap[seriesName] || seriesName;
      },
      labels: {
        colors: Object.keys(chartData.seriesData).map((metric) =>
          selectedMetric === metric ? colors[metric] : "#AAAAAA"
        ),
      },
      markers: {
        fillColors: Object.keys(chartData.seriesData).map((metric) =>
          selectedMetric === metric ? colors[metric] : "#AAAAAA"
        ),
      },
    },
  };

  const seriesData = {};
  Object.keys(chartData.seriesData).forEach((metric) => {
    seriesData[metric] = chartData.seriesData[metric].map(
      (value) => value ?? 0
    );
  });

  const series = Object.keys(seriesData).map((metric) => ({
    name: metric,
    data: selectedMetric === metric ? seriesData[metric] : [],
  }));

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      height={height ? height : 550}
    />
  );
};

export default WeightWaistChart;

import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Card, CardContent, Typography } from "@mui/material";
import AlertDialogSlide from "./ModalToShowAnExercise";
import DisplaySet from "./DisplaySet";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MySlider = ({
  elements,
  isPaused,
  isFullScreen,
  setIsFullScreen,
  toggleFullScreen,
}) => {
  console.log(elements, "dsfkjklsajd");
  const [openSingleExercise, setOpenSingleExercise] = useState(false);
  const [singleExercise, setSingleExercise] = useState("");

  const [showDetails, setShowDetails] = useState([]);

  const toggleDetails = (index) => {
    setShowDetails((prev) => {
      if (prev.includes(index)) {
        return prev.filter((section) => section !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  // Number of elements to show per slide
  const elementsPerSlide = 6;

  // Function to chunk the array into subarrays
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  // Chunk the elements array into subarrays for slides
  const slides = chunkArray(elements, elementsPerSlide);

  // State to track the active slide index
  const [activeIndex, setActiveIndex] = useState(0);

  // Update activeIndex on slide change
  const handleSlideChange = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };
  const getMinutesForInterval = (mainindex, index) => {
    const list = [...elements];
    const interval = list[mainindex].interval_duration;
    if (interval <= 60) {
      return index + 1;
    }
    if (interval % 60 == 0) {
      return `${
        (interval / 60) * index +
        1 +
        " – " +
        ((interval / 60) * index + interval / 60)
      }`;
    }
    let startInterval = interval * index;
    let endtInterval = interval * index + interval;
    let startintervalText =
      interval * index
        ? Math.floor(startInterval / 60) +
          " " +
          ":" +
          formatWithLeadingZeros(startInterval % 60)
        : 0;
    let endIntervalText =
      Math.floor(endtInterval / 60) +
      " " +
      ":" +
      formatWithLeadingZeros(endtInterval % 60);

    return `${startintervalText + " – " + endIntervalText}`;
  };
  useEffect(() => {
    setActiveIndex(0);
  }, [elements]);

  return (
    <div>
      <AlertDialogSlide
        open={openSingleExercise}
        setOpen={setOpenSingleExercise}
        exercise={singleExercise}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        toggleFullScreen={toggleFullScreen}
      />
      <div className="text-center mt-3">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`slider-indicator ${
              index === activeIndex ? "active" : ""
            }`}
            onClick={() => setActiveIndex(index)}
          >
            {index + 1}
          </span>
        ))}
      </div>
      <Carousel
        interval={!isPaused ? 3000 : null}
        activeIndex={activeIndex}
        onSelect={handleSlideChange}
        controls={false}
        indicators={false}
        key={activeIndex}
      >
        {slides.map((slide, index) => (
          <Carousel.Item key={index}>
            <div className="row  flex-wrap p-2" key={index}>
              {slide.map((exercise, elementIndex) => {
                console.log(exercise, "exerciseexerciseexercise");
                return (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 mb-4"
                    key={exercise.id}
                  >
                    <Card
                      key={exercise.id}
                      sx={{
                        /*height: "490px"*/
                        height: "100%",
                      }}
                      className="base-card"
                      onClick={() => {
                        setOpenSingleExercise(true);
                        setSingleExercise(exercise);
                      }}
                    >
                      <CardContent sx={{ padding: "8px" }}>
                        <div key={exercise.id} className="text-start">
                          {exercise.exercise_type === "something_else" && (
                            <>
                              <div
                                className="text-center"
                                style={{
                                  color: "#ff7f00",
                                  marginBottom: "20px",
                                }}
                              >
                                <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                                Something Else
                              </div>

                              <Card className="exercise-card mb-1 d-flex align-items-center">
                                <div>
                                  <Icon
                                    icon="ph:tag-simple-fill"
                                    className="ms-1"
                                  />
                                </div>
                                <div>
                                  <div className="px-2 d-flex">
                                    <Typography
                                      variant="subtitle2"
                                      component="div"
                                    >
                                      {exercise.title}
                                    </Typography>
                                    <div
                                      className="ms-2 detail_open_icon2"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleDetails(elementIndex);
                                      }}
                                    >
                                      {showDetails.includes(elementIndex) ? (
                                        <ExpandLessIcon />
                                      ) : (
                                        <ExpandMoreIcon />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Card>
                              {showDetails.includes(elementIndex) &&
                              exercise?.description ? (
                                <div className="p-2 overflow_warp">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: exercise?.description,
                                    }}
                                  ></div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}

                          {exercise.exercise_type === "amrap" &&
                            exercise.emom_amrap &&
                            exercise.emom_amrap.length > 0 && (
                              <div>
                                <div
                                  className="text-center"
                                  style={{ color: "#ff7f00" }}
                                >
                                  <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                                  AMRAP
                                </div>
                                {exercise.emom_amrap.map(
                                  (interval, intervalIndex) => (
                                    <div key={intervalIndex}>
                                      <div
                                        style={{
                                          fontSize: "11px",
                                          color: "#ff7f00",
                                        }}
                                      >
                                        Movements
                                      </div>
                                      {interval.map((exerData) => (
                                        <div key={exerData.id}>
                                          <Card className="exercise-card my-1 d-flex align-items-center ">
                                            <div>
                                              <Icon
                                                icon="ph:tag-simple-fill"
                                                className="ms-1"
                                              />
                                            </div>
                                            <div>
                                              <div className="d-flex justify-content-center"></div>
                                              <div className="px-2">
                                                <Typography
                                                  variant="subtitle2"
                                                  component="div"
                                                >
                                                  {exerData.exercise_info.title}
                                                </Typography>
                                                <Typography
                                                  variant="subtitle2"
                                                  sx={{ fontSize: 10 }}
                                                >
                                                  <DisplaySet
                                                    sets_array={exerData?.sets}
                                                    parameters={
                                                      exerData?.parameters
                                                    }
                                                  />
                                                </Typography>
                                              </div>
                                            </div>
                                          </Card>
                                        </div>
                                      ))}
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                          {exercise.exercise_type === "reps_for_time" &&
                            exercise.emom_amrap &&
                            exercise.emom_amrap.length > 0 && (
                              <div>
                                <div
                                  className="text-center"
                                  style={{ color: "#ff7f00" }}
                                >
                                  <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                                  Reps For Time
                                </div>
                                {exercise.emom_amrap.map(
                                  (interval, intervalIndex) => (
                                    <div key={intervalIndex}>
                                      <div
                                        style={{
                                          fontSize: "11px",
                                          color: "#ff7f00",
                                        }}
                                      >
                                        Movements
                                      </div>
                                      {interval.map((exerData) => (
                                        <div key={exerData.id}>
                                          <Card className="exercise-card my-1 d-flex align-items-center ">
                                            <div>
                                              <Icon
                                                icon="ph:tag-simple-fill"
                                                className="ms-1"
                                              />
                                            </div>
                                            <div>
                                              <div className="d-flex justify-content-center"></div>
                                              <div className="px-2">
                                                <Typography
                                                  variant="subtitle2"
                                                  component="div"
                                                >
                                                  {exerData.exercise_info.title}
                                                </Typography>
                                                <Typography
                                                  variant="subtitle2"
                                                  sx={{ fontSize: 10 }}
                                                >
                                                  <DisplaySet
                                                    type={"reps_for_time"}
                                                    sets_array={exerData?.sets}
                                                    parameters={
                                                      exerData?.parameters
                                                    }
                                                  />
                                                </Typography>
                                              </div>
                                            </div>
                                          </Card>
                                        </div>
                                      ))}
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                          {exercise.exercise_type === "rounds_for_time" &&
                            exercise.emom_amrap &&
                            exercise.emom_amrap.length > 0 && (
                              <div>
                                <div
                                  className="text-center"
                                  style={{ color: "#ff7f00" }}
                                >
                                  <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                                  Rounds For Time
                                </div>
                                {exercise.emom_amrap.map(
                                  (interval, intervalIndex) => (
                                    <div key={intervalIndex}>
                                      <div
                                        style={{
                                          fontSize: "11px",
                                          color: "#ff7f00",
                                        }}
                                      >
                                        Movements
                                      </div>
                                      {interval.map((exerData) => (
                                        <div key={exerData.id}>
                                          <Card className="exercise-card my-1 d-flex align-items-center ">
                                            <div>
                                              <Icon
                                                icon="ph:tag-simple-fill"
                                                className="ms-1"
                                              />
                                            </div>
                                            <div>
                                              <div className="d-flex justify-content-center"></div>
                                              <div className="px-2">
                                                <Typography
                                                  variant="subtitle2"
                                                  component="div"
                                                >
                                                  {exerData.exercise_info.title}
                                                </Typography>
                                                <Typography
                                                  variant="subtitle2"
                                                  sx={{ fontSize: 10 }}
                                                >
                                                  <DisplaySet
                                                    sets_array={exerData?.sets}
                                                    parameters={
                                                      exerData?.parameters
                                                    }
                                                  />
                                                </Typography>
                                              </div>
                                            </div>
                                          </Card>
                                        </div>
                                      ))}
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                          {exercise.exercise_type === "emom" &&
                            exercise.emom_amrap &&
                            exercise.emom_amrap.length > 0 && (
                              <div>
                                <h4
                                  className="text-center"
                                  style={{ color: "#ff7f00" }}
                                >
                                  <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                                  EMOM
                                </h4>
                                {exercise?.emom_amrap?.map(
                                  (interval, intervalIndex) => {
                                    return (
                                      <div className="my-1">
                                        <div
                                          style={{
                                            fontSize: "11px",
                                            color: "#ff7f00",
                                          }}
                                        >
                                          Minute{" "}
                                          {getMinutesForInterval(
                                            index,
                                            intervalIndex
                                          )}
                                        </div>
                                        {interval?.map((exerData) => {
                                          return (
                                            <div
                                              // className="col-4 mt-2"
                                              key={exerData.id}
                                            >
                                              <Card className="exercise-card my-1 d-flex align-items-center">
                                                <div>
                                                  <Icon
                                                    icon="ph:tag-simple-fill"
                                                    className="ms-1"
                                                  />
                                                </div>
                                                <div>
                                                  <div className="px-2">
                                                    <Typography
                                                      variant="subtitle2"
                                                      component="div"
                                                    >
                                                      {
                                                        exerData.exercise_info
                                                          .title
                                                      }
                                                    </Typography>
                                                    <Typography
                                                      variant="subtitle2"
                                                      sx={{ fontSize: 10 }}
                                                    >
                                                      <DisplaySet
                                                        sets_array={
                                                          exerData?.sets
                                                        }
                                                        parameters={
                                                          exerData?.parameters
                                                        }
                                                      />
                                                    </Typography>
                                                  </div>
                                                </div>
                                              </Card>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}

                          {exercise.sub_exercises &&
                            exercise.sub_exercises.length > 0 && (
                              <div>
                                <div
                                  className="text-center"
                                  style={{
                                    color: "#ff7f00",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                                  Superset
                                </div>
                                {exercise.sub_exercises.map((exerData) => (
                                  <div key={exerData.id}>
                                    <Card className="exercise-card mb-1 d-flex align-items-center">
                                      <div>
                                        <Icon
                                          icon="ph:tag-simple-fill"
                                          className="ms-1"
                                        />
                                      </div>

                                      <div>
                                        <div className="px-2">
                                          <Typography
                                            variant="subtitle2"
                                            component="div"
                                            className="d-flex"
                                          >
                                            {exerData.exercise_info.title}
                                            {exerData?.sets?.length > 1 && (
                                              <div
                                                className="ms-2 detail_open_icon"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  toggleDetails(elementIndex);
                                                }}
                                              >
                                                {showDetails.includes(
                                                  elementIndex
                                                ) ? (
                                                  <>
                                                    <ExpandLessIcon /> {"Sets"}{" "}
                                                    {exerData?.sets?.length}
                                                  </>
                                                ) : (
                                                  <>
                                                    <ExpandMoreIcon /> {"Sets"}{" "}
                                                    {exerData?.sets?.length}
                                                  </>
                                                )}
                                              </div>
                                            )}
                                          </Typography>
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: 10 }}
                                          >
                                            <DisplaySet
                                              sets_array={
                                                showDetails.includes(
                                                  elementIndex
                                                )
                                                  ? exerData?.sets
                                                  : [exerData?.sets[0]]
                                              }
                                              parameters={exerData?.parameters}
                                            />
                                          </Typography>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                ))}
                              </div>
                            )}

                          {exercise.exercise_type == "general" &&
                            exercise.sub_exercises.length == 0 && (
                              <>
                                <div
                                  className="text-center"
                                  style={{
                                    color: "#ff7f00",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                                  General Exercise
                                </div>

                                <Card className="exercise-card mb-1 d-flex align-items-center">
                                  <div>
                                    <Icon
                                      icon="ph:tag-simple-fill"
                                      className="ms-1"
                                    />
                                  </div>
                                  <div>
                                    <div className="px-2">
                                      <Typography
                                        variant="subtitle2"
                                        component="div"
                                        className="d-flex"
                                      >
                                        {exercise.exercise_info.title}
                                        {exercise?.sets?.length > 1 && (
                                          <div
                                            className="ms-2 detail_open_icon"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              toggleDetails(elementIndex);
                                            }}
                                          >
                                            {showDetails.includes(
                                              elementIndex
                                            ) ? (
                                              <>
                                                <ExpandLessIcon /> {"Sets"}{" "}
                                                {exercise?.sets?.length}
                                              </>
                                            ) : (
                                              <>
                                                <ExpandMoreIcon /> {"Sets"}{" "}
                                                {exercise?.sets?.length}
                                              </>
                                            )}
                                          </div>
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 10 }}
                                      >
                                        <DisplaySet
                                          sets_array={
                                            showDetails.includes(elementIndex)
                                              ? exercise?.sets
                                              : [exercise?.sets[0]]
                                          }
                                          parameters={exercise?.parameters}
                                        />
                                      </Typography>
                                    </div>
                                  </div>
                                </Card>
                              </>
                            )}
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default MySlider;

const _get_user_from_localStorage = () => {
  //console.log("localStorage.getItem", localStorage.getItem("user_data"));

  const user_profile = localStorage.getItem("user_data");
  if (
    user_profile &&
    user_profile !== undefined &&
    user_profile !== "undefined" &&
    user_profile !== null
  ) {
    //console.log("if");
    return JSON.parse(localStorage.getItem("user_data"));
  } else {
    //console.log("else");
    return {};
  }
};

const _get_day_calories_local = () => {
  const storedCalories = localStorage.getItem("day_calories");
  if (
    storedCalories &&
    storedCalories !== undefined &&
    storedCalories !== "undefined" &&
    storedCalories !== null
  ) {
    return JSON.parse(localStorage.getItem("day_calories"));
  } else {
    return {};
  }
};

module.exports = {
  _get_user_from_localStorage,
  _get_day_calories_local,
};

import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  CircularProgress,
  Tooltip,
  Typography,
  FormHelperText,
} from "@mui/material";
import MUIAutocomplete from "./MUIAutocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  copy_paste_member_workout,
  paste_multiple_workouts,
  program_workout_reciever_list_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
}));

const PastedWorkoutsClient = ({
  selectedWorkouts,
  setSelectedWorkouts,
  setDataCopy,
  setIsPastedWorkouts,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [programsList, setProgramsList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isFormLoading1, setIsFormLoading1] = useState(false);
  const [hoveredCell, setHoveredCell] = useState({ rowIndex: 0, colIndex: 0 });
  const [serchText, setSerchText] = useState("");
  const [serchTextMember, setSerchTextMember] = useState("");
  const [inputs, setInputs] = useState({
    reciever_type: "program",
    programme: null,
    members: null,
    day: 1,
    target_date: new Date(),
    no_of_weeks: 1,
    difficulty_levels: [],
  });

  const [tableData, setTableData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
    if (name === "reciever_type") {
      setSerchText("");
      setProgramsList([]);
      setMembersList([]);
      setInputs((prev) => ({ ...prev, programme: null, members: null }));
    }
  };

  const handleChangeOthers = (e, name) => {
    setInputs((prev) => ({ ...prev, [name]: e }));
    if (name === "members") {
      setInputs((prev) => ({ ...prev, ["programme"]: null }));
    }
  };

  const getProgrammesList = async (type, serch) => {
    let result = await program_workout_reciever_list_api(type, serch);
    if (result.code === 200) {
      setisLoading(false);
      if (type === "program") {
        let data = result.response.map((program) => {
          let title = program.title + " (" + program.program_length_type + ")";
          return {
            ...program,
            title,
          };
        });
        if (params?.slug) {
          data = data.filter(
            (program) => program.program_slug !== params?.slug
          );
        }
        setProgramsList(data);
      } else {
        let data = result.response.map((member) => {
          let title =
            member.first_name +
            " " +
            member.last_name +
            " (" +
            member.email +
            ")";
          return {
            ...member,
            title,
          };
        });
        setMembersList(data);
      }
    } else {
      setisLoading(false);
    }
  };

  const handleCellHover = (rowIndex, colIndex) => {
    setHoveredCell({ rowIndex, colIndex });
  };

  const handleSubmit = async (e, value_navigate) => {
    e.preventDefault();

    let difficulty_levels = [];

    if (selectedWorkouts.length > 0) {
      difficulty_levels = selectedWorkouts.map(
        (item) => item.difficulty_levels
      );
    }

    const isValid = difficulty_levels.every((levels) =>
      levels.includes(inputs?.programme?.member_difficutly_level)
    );

    if (inputs.reciever_type === "member" && !inputs.members) {
      enqueueSnackbar("Members are required.", { variant: "error" });
      return;
    }

    if (!inputs.programme) {
      enqueueSnackbar("Program is required.", { variant: "error" });
      return;
    }

    if (
      inputs.programme &&
      inputs.reciever_type === "program" &&
      inputs.programme.difficulty_level &&
      inputs.difficulty_levels.length === 0
    ) {
      enqueueSnackbar(`Please Select Levels`, { variant: "error" });
      return;
    }

    if (
      inputs.programme &&
      inputs.reciever_type === "member" &&
      inputs?.programme?.difficulty_level &&
      !inputs?.programme?.member_difficutly_level
    ) {
      enqueueSnackbar(
        "The difficulty level for the selected member's programme is not set yet.",
        { variant: "error" }
      );
      return;
    }

    if (
      inputs.reciever_type === "member" &&
      !isValid &&
      inputs?.programme?.difficulty_level
    ) {
      enqueueSnackbar(
        "Please copy only those workouts that match the selected member's programme difficulty level.",
        { variant: "error" }
      );
      return;
    }

    if (value_navigate) {
      setIsFormLoading1(true);
    } else {
      setIsFormLoading(true);
    }

    const workoutsIds = selectedWorkouts.map((workout) => workout._id);
    let postData = {
      copiedWorkoutIds: workoutsIds,
      // workout_copy_type: inputs.programme.program_length_type,
      target_program_id: inputs.programme._id,
      member_id: params.id,
      difficulty_levels: inputs.difficulty_levels,
    };

    if (inputs.programme.program_length_type === "limited") {
      postData.targetStartDay = Number(inputs.day);
    } else {
      postData.targetStartDate = moment(inputs.target_date).format(
        "YYYY-MM-DD"
      );
    }

    if (inputs.reciever_type === "member") {
      postData.target_member_id = inputs.members._id;
    }

    const result = await copy_paste_member_workout(postData);
    if (result.code === 200) {
      if (value_navigate) {
        localStorage.setItem(
          "day_calories",
          JSON.stringify({
            high_day_calories: inputs?.members?.high_day_calories,
            low_day_calories: inputs?.members?.low_day_calories,
            regular_day_calories: inputs?.members?.regular_day_calories,
            kcal_type: inputs?.members?.kcal_type,
            high_day_carb: inputs?.members?.high_day_carb,
            high_day_fat: inputs?.members?.high_day_fat,
            high_day_protein: inputs?.members?.high_day_protein,
            low_day_carb: inputs?.members?.low_day_carb,
            low_day_fat: inputs?.members?.low_day_fat,
            low_day_protein: inputs?.members?.low_day_protein,
            gender: inputs?.members?.gender || "",
            is_menstrual_cycle: inputs?.members?.is_menstrual_cycle || "",
          })
        );
        if (inputs.reciever_type === "member") {
          navigate(
            `/member/${inputs.members._id}/member-MemberCalendar/${inputs.programme._id}`,
            {
              state: {
                ...inputs.programme,
                title: inputs.programme.title.replace(/\(.*?\)/g, "").trim(),
                _id: inputs.programme,
              },
            }
          );
        } else {
          if (inputs.programme.program_length_type === "limited") {
            navigate(
              `/programmes-limited-calendar/${inputs.programme.program_slug}`,
              {
                state: {
                  ...inputs.programme,
                  title: inputs.programme.title.replace(/\(.*?\)/g, "").trim(),
                },
              }
            );
          } else {
            navigate(`/programmes-calendar/${inputs.programme.program_slug}`, {
              state: {
                ...inputs.programme,
                title: inputs.programme.title.replace(/\(.*?\)/g, "").trim(),
              },
            });
          }
        }
      }
      setSelectedWorkouts([]);
      setDataCopy(false);
      setIsPastedWorkouts(false);
      setIsFormLoading(false);
      setIsFormLoading1(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsFormLoading(false);
      setIsFormLoading1(false);
    }
  };

  useEffect(() => {
    if (inputs.members) {
      let data = inputs.members.program.map((program) => {
        let title = program.title + " (" + program.program_length_type + ")";
        return {
          ...program,
          title,
        };
      });
      data = data.filter((program) => program.access_status === true);
      setProgramsList(data);
    }
  }, [inputs.members]);

  useEffect(() => {
    getProgrammesList(inputs.reciever_type, serchText);
  }, [inputs.reciever_type, serchText]);

  useEffect(() => {
    let week = hoveredCell.rowIndex + 1;
    let days = hoveredCell.colIndex + 1;
    let checkNumber = (week - 1) * 7 + days;
    setInputs((prev) => ({ ...prev, ["day"]: checkNumber }));
  }, [hoveredCell]);

  useEffect(() => {
    if (
      inputs.programme &&
      inputs.programme.program_length_type === "limited"
    ) {
      const weeks = inputs.programme.no_of_weeks || 0;
      setInputs((prev) => ({
        ...prev,
        ["no_of_weeks"]: weeks,
      }));
      setTableData(Array(weeks).fill(Array(7).fill("")));
    }
    if (
      inputs.programme &&
      inputs?.programme?.member_difficutly_level &&
      inputs.reciever_type === "member"
    ) {
      setInputs((prev) => ({
        ...prev,
        ["difficulty_levels"]: [inputs?.programme.member_difficutly_level],
      }));
    }
  }, [inputs.programme]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="col-lg-12 mt-4">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Added In</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="reciever_type"
            value={inputs.reciever_type}
            label="Added In"
            onChange={handleChange}
          >
            <MenuItem value="program">Programme</MenuItem>
            <MenuItem value="member">Member</MenuItem>
          </Select>
        </FormControl>
      </div>
      {inputs.reciever_type === "member" && (
        <div className="col-lg-12 mt-3">
          <MUIAutocomplete
            inputLabel="Members"
            required={true}
            selectedOption={inputs.members}
            setSelectedOption={(e) => {
              handleChangeOthers(e, "members");
            }}
            optionsList={membersList}
            setCustomSearch={setSerchText}
            autoComplete="new-password"
            name="title"
          />
        </div>
      )}
      <div className="col-lg-12 mt-3">
        <MUIAutocomplete
          inputLabel="Programme"
          required={true}
          selectedOption={inputs.programme}
          setSelectedOption={(e) => {
            handleChangeOthers(e, "programme");
          }}
          optionsList={programsList}
          setCustomSearch={
            inputs.reciever_type === "member"
              ? setSerchTextMember
              : setSerchText
          }
          autoComplete="new-password"
          name="title"
        />
      </div>
      {inputs.programme && (
        <>
          {inputs.programme.program_length_type === "limited" && (
            <div className="col-lg-12 mt-3">
              <table className="fixedTable" id="dragTable">
                <thead>
                  <tr>
                    {[...Array(7)].map((_, index) => {
                      return (
                        <th className="fixed-table-head" key={index}>
                          <div className="mt-2 mb-2" style={{ opacity: 0.7 }}>
                            Day {index + 1}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="">
                      {row.map((col, colIndex) => {
                        const isSelected =
                          hoveredCell.rowIndex === rowIndex &&
                          hoveredCell.colIndex === colIndex;

                        return (
                          <td
                            className={`fixed-table-data hover_index ${
                              isSelected ? "selected_cell" : ""
                            }`}
                            key={colIndex}
                            onClick={() => handleCellHover(rowIndex, colIndex)}
                          >
                            <div
                              style={{
                                position: "relative",
                                height: "100%",
                                width: "100%",
                                marginTop: 15,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  ml: 0.5,
                                  mt: 2,
                                  opacity: 0.6,
                                }}
                                variant="body2"
                              >
                                Week {rowIndex + 1}
                              </Typography>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {inputs.programme.program_length_type === "unlimited" && (
            <>
              <div className="col-11 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Target Date"
                    inputFormat="dd/MM/yyyy"
                    value={inputs.target_date}
                    onChange={(e) => {
                      handleChangeOthers(e, "target_date");
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-1 mt-3">
                <Tooltip title="Workouts will be copied starting from the target date in the selected programme.">
                  <InfoIcon
                    style={{
                      marginTop: "16px",
                    }}
                  />
                </Tooltip>
              </div>
            </>
          )}
        </>
      )}

      {inputs.programme &&
        inputs.reciever_type === "program" &&
        inputs?.programme?.difficulty_level && (
          <div className="col-12 mt-2">
            <FormHelperText sx={{ fontSize: 15 }}>
              {<InfoIcon sx={{ width: 16 }} />}
              {inputs.programme.difficulty_level ? (
                <>
                  &nbsp;Copied workouts will be pasted with the selected
                  difficulty level in the following field:
                </>
              ) : (
                <>
                  &nbsp;Copied workouts will be pasted with all 3 difficulty
                  levels.
                </>
              )}
            </FormHelperText>
          </div>
        )}
      {inputs.programme &&
        inputs.reciever_type === "member" &&
        inputs?.programme?.member_difficutly_level && (
          <div className="col-12 mt-2">
            <FormHelperText sx={{ fontSize: 15 }}>
              {<InfoIcon sx={{ width: 16 }} />}{" "}
              <>
                Copied workouts will be pasted with difficulty level assigned to
                member's programme:{" "}
                {`(${show_proper_words(
                  inputs?.programme.member_difficutly_level
                )})`}
              </>
            </FormHelperText>
          </div>
        )}
      {inputs?.programme &&
        inputs?.programme?.difficulty_level &&
        inputs?.reciever_type === "program" && (
          <div className="col-12 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Difficulty Levels
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                name="difficulty_levels"
                value={inputs.difficulty_levels}
                label="Difficulty Levels"
                onChange={handleChange}
              >
                <MenuItem value="novice">Novice</MenuItem>
                <MenuItem value="intermediate">Intermediate</MenuItem>
                <MenuItem value="advanced">Advanced</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      <div className="col-lg-12 mt-3 text-end">
        <Tooltip
          title={
            inputs.reciever_type === "member" && programsList.length === 0
              ? "No program assigned to the selected member."
              : ""
          }
          placement="top"
        >
          <Button
            type={
              inputs.reciever_type === "member" && programsList.length === 0
                ? "button"
                : "submit"
            }
            variant="contained"
            color="primary"
            disabled={isFormLoading || isFormLoading1}
          >
            {isFormLoading ? "Loading..." : "Save"}
          </Button>
          <Button
            type="button"
            onClick={(e) => {
              handleSubmit(e, true);
            }}
            className="ms-2"
            variant="contained"
            color="primary"
            disabled={isFormLoading || isFormLoading1}
          >
            {isFormLoading1 ? "Loading..." : "Save And Navigate"}
          </Button>
        </Tooltip>
      </div>
    </form>
  );
};

export default PastedWorkoutsClient;

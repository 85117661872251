import React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Button,
} from "@mui/material";
import TinyEditor from "../../components/ckeditor/Ckeditor";
import { add_food_list_api, edit_food_list_api } from "src/DAL/Food/Food";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

let units_object = {
  measurement_unit_name: "",
  carb: "",
  protein: "",
  fat: "",
  type: "cooked",
  total_calories: 0,
};

export default function AddOrEditFood() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [detailDescriptionCk, setDetailDescriptionCk] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [unitsArray, setUnitsArray] = useState([units_object]);
  const [inputs, setInputs] = useState({
    name: "",
    image: "",
    status: true,
  });

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let final_arry = unitsArray.map((item) => {
      return {
        measurement_unit_name: item.measurement_unit_name,
        carb: Number(item.carb),
        protein: Number(item.protein),
        fat: Number(item.fat),
        total_calories: Number(item.total_calories),
        type: item.type,
      };
    });

    const formData = new FormData();
    formData.append("name", inputs.name);
    if (file) {
      formData.append("image", inputs.image);
    }
    formData.append("description", detailDescriptionCk);
    formData.append("measurement_units", JSON.stringify(final_arry));
    formData.append("status", inputs.status);
    setIsLoading(true);
    const result = params?.food_id
      ? await edit_food_list_api(formData, params?.food_id)
      : await add_food_list_api(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/food`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeUnits = (e, index) => {
    const { name, value } = e.target;

    // Create a new array copy
    const updatedUnits = [...unitsArray];

    // Create a new object copy for the specific index
    updatedUnits[index] = { ...updatedUnits[index], [name]: value };

    // Convert values to numbers and calculate total calories
    const fat = parseFloat(updatedUnits[index].fat) || 0;
    const carb = parseFloat(updatedUnits[index].carb) || 0;
    const protein = parseFloat(updatedUnits[index].protein) || 0;
    updatedUnits[index].total_calories = fat * 9 + carb * 4 + protein * 4;

    // Update state
    setUnitsArray(updatedUnits);
  };

  const handleAddUnit = () => {
    setUnitsArray((old) => [...old, units_object]);
  };

  const handleRemoveUnit = (index) => {
    const updatedItems = [...unitsArray];
    updatedItems.splice(index, 1);
    setUnitsArray(updatedItems);
  };

  useEffect(() => {
    if (params && params.food_id) {
      if (state) {
        setInputs(state);
        setDetailDescriptionCk(state?.description);
        setUnitsArray(state?.measurement_units);
      }
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <div className="me-2">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <h2>{`${params?.food_id ? "Edit" : "Add"} Food`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Food Name"
              variant="outlined"
              fullWidth
              required
              name="name"
              value={inputs.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 mt-3 text-muted">
            Food values for carbs, proteins, and fats are per 100 grams.
          </div>
          <div className="col-12 mt-3">
            {unitsArray.length > 0 &&
              unitsArray.map((units, index) => {
                return (
                  <>
                    <div className="row mb-3 setting-card px-0 mx-1">
                      <div className="col-11">
                        <div className="row">
                          <div className="col-12 col-md-4">
                            <TextField
                              id="outlined-basic"
                              label="Serving"
                              variant="outlined"
                              fullWidth
                              size="small"
                              required
                              name="measurement_unit_name"
                              value={units.measurement_unit_name}
                              onChange={(e) => {
                                handleChangeUnits(e, index);
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-4 mt-3 mt-md-0">
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="type"
                                value={units.type}
                                label="Type"
                                onChange={(e) => {
                                  handleChangeUnits(e, index);
                                }}
                              >
                                <MenuItem value="cooked">Cooked</MenuItem>
                                <MenuItem value="raw">Raw</MenuItem>
                                <MenuItem value="dry">Dry</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-12 col-md-4 mt-3 mt-md-0">
                            <TextField
                              id="outlined-basic"
                              label="Carb"
                              variant="outlined"
                              fullWidth
                              size="small"
                              required
                              type="number"
                              name="carb"
                              value={units.carb}
                              onChange={(e) => {
                                handleChangeUnits(e, index);
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-4 mt-3">
                            <TextField
                              id="outlined-basic"
                              label="Protein"
                              variant="outlined"
                              fullWidth
                              size="small"
                              required
                              type="number"
                              name="protein"
                              value={units.protein}
                              onChange={(e) => {
                                handleChangeUnits(e, index);
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-4 mt-3">
                            <TextField
                              id="outlined-basic"
                              label="Fat"
                              variant="outlined"
                              fullWidth
                              required
                              size="small"
                              type="number"
                              name="fat"
                              value={units.fat}
                              onChange={(e) => {
                                handleChangeUnits(e, index);
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-4 mt-3">
                            <TextField
                              id="outlined-basic"
                              label="Calories"
                              variant="outlined"
                              fullWidth
                              size="small"
                              required
                              type="number"
                              name="total_calories"
                              value={units.total_calories.toFixed(2)}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-1 duplicate-icon-box">
                        <AddCircleOutlineIcon
                          className="duplicate-icon mb-2"
                          onClick={handleAddUnit}
                        />
                        {unitsArray.length !== 1 && (
                          <RemoveCircleOutlineIcon
                            style={{ fill: "red" }}
                            className="duplicate-icon"
                            onClick={() => handleRemoveUnit(index)}
                          />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="">Upload Image</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-lg-2 col-md-2 col-6">
                {file ? (
                  <img className="image-border" src={file} height={50} />
                ) : (
                  <>
                    {inputs?.image && (
                      <img
                        className="image-border"
                        src={s3baseUrl + inputs?.image}
                        height={50}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-6 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />
                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name && (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-4">
            <h4>Description</h4>
            <TinyEditor
              setDetailDescription={setDetailDescriptionCk}
              detailDescriptionCk={detailDescriptionCk}
            />
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              {params?.food_id ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

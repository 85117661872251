import {
  Avatar,
  CircularProgress,
  FormControl,
  TextField,
  FormHelperText,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { makeStyles } from "@mui/styles";

import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import { s3baseUrl } from "src/config/config";
import { EditWorkoutApi } from "src/DAL/workout/workout";

import { PhotoCamera } from "@mui/icons-material";
import FoodMattersAndMindsetUI from "./FoodMattersAndMindsetUI";
import NotePopup from "../Calender/NotePopup";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const EditMindset = ({}) => {
  // console.log(addArray, setaddArray, "dflkadsjjkfahds");

  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [serchText, setSerchText] = useState("");
  const [addArray, setaddArray] = useState([
    {
      exercise: "",
      title: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          reps: "",
          rest: "",
        },
      ],
      description: "",

      superSet: [],
      superset_sets: "",
    },
  ]);

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isReaload, setIsReaload] = useState(false);
  const [exercise, setExercise] = useState([]);
  const [workoutTitle, setWorkoutTitle] = useState(state.title);
  const [file, setImage] = React.useState();
  const [previews, setPreviews] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [oldProfile, setOldProfile] = useState();

  const [workoutDescription, setWorkoutDescription] = useState(
    state.description
  );
  const handleAdd = () => {
    let list = addArray;
    list.splice(addArray.length, 0, {
      exercise: "",
      title: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
          vdot: null,
          race_type: "c",
          pace_type: "c_km",
          pace: 0,
          paceUp: 423,
          optionsType: [
            { title: "1 KM Pace", key: "c_km" },
            { title: "1 MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "something_else",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    });

    setaddArray([...list]);
  };

  const [oldImage, setOldImage] = useState();
  const [inputs, setInputs] = useState({
    video_url: "",
    image: "",
    workout_note: "",
  });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpload = (event) => {
    // console.log("dsfksadjflkas", event.target.files[0]);
    setImageStatus(true);
    // setImage(event.target.files[0]);
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };

  const fileChangedHandler = (e) => {
    setImageStatus(true);
    setImage(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files[0], "sdalkfjlkdsjfa");
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      urlPatternValidation(inputs.video_url) === false &&
      inputs.video_url != ""
    ) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
      setIsLoading(false);
      return;
    }
    if (inputs.video_url == "" && (inputs.image || oldImage)) {
      enqueueSnackbar("Please Enter Video URL", {
        variant: "error",
      });
      isError = true;
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }

    if (inputs.video_url && !inputs.image && !oldImage) {
      enqueueSnackbar("Please Upload Video Thumbnail", { variant: "error" });
      isError = true;
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    setIsLoading(true);
    let isError = false;
    let exerciesArray = addArray.map((item, mainIn) => {
      console.log(item, "sdkfljkajs");
      if (
        item.type != "emom" &&
        item.type != "amrap" &&
        item.type != "something_else" &&
        !item.exercise?._id &&
        item.superSet.length == 0
      ) {
        enqueueSnackbar("please select an exercise", { variant: "error" });
        isError = true;
      }
      if (item.type == "something_else" && !item?.title) {
        enqueueSnackbar("please add name", {
          variant: "error",
        });
        isError = true;
      }
      let data = {
        exercise_info: {
          _id: item?.superSet[0]?.exercise?._id
            ? item?.superSet[0]?.exercise?._id
            : item.exercise?._id,
          title: item?.superSet[0]?.exercise?.title
            ? item?.superSet[0]?.exercise?.title
            : item.exercise?.title,
          image: item?.superSet[0]?.exercise?.image
            ? item?.superSet[0]?.exercise?.image
            : item.exercise?.image,
        },
        title: item?.title,
        description: item.description,
        parameters: item.parameters,
      };
      if (item?._id) {
        data["_id"] = item?._id;
      }
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.rounds) {
        data["emom_rounds"] = item.rounds;
      }
      if (item.type) {
        data["exercise_type"] = item.type;
      }
      if (item.time_interval) {
        data["interval_duration"] = item.time_interval;
      }
      if (item.set) {
        data["sets"] = [];
        if (item.set.length > 0) {
          data["sets"] = item.set.map((item) => {
            console.log("dskjfjksjdklfja", item);
            let data = {};
            if (item.vdot) {
              data["vdot"] = item.vdot;
            }
            if (item.race_type) {
              data["race_type"] = item.race_type;
            }
            if (item.pace_type) {
              data["pace_type"] = item.pace_type;
            }
            if (item.pace) {
              data["pace_low"] = item.pace;
            }
            if (item.paceUp) {
              data["pace_up"] = item.paceUp;
            }
            if (item.optionsType) {
              data["pace_options"] = item.optionsType;
            }
            if (item.mhr) {
              data["mhr"] = item.mhr;
            }
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.time) {
              data["duration"] = item.time;
            }
            if (item.weightType) {
              data["weight_unit"] = item.weightType;
            }

            if (item.distance) {
              data["distance"] = item.distance;
            }
            if (item.distanceType) {
              data["distance_unit"] = item.distanceType;
            }
            if (item.timeType) {
              data["duration_unit"] = item.timeType;
            }
            if (item.restType) {
              data["rest_unit"] = item.restType;
            }
            if (item.tempo) {
              data["tempo"] = item.tempo;
            }
            return data;
          });
        }
      }
      if (item.type == "emom" || item.type == "amrap") {
        data["emom_amrap"] = [];
        if (item.emomSets.length > 0) {
          item.emomSets.map((item1, ind) => {
            console.log(item1, "sjdkfksjdka");
            if (item1.length > 0) {
              data["emom_amrap"][ind] = item1.map((item2) => {
                if (isError) {
                  return;
                }
                if (!item2.exercise?._id) {
                  if (item.type == "emom") {
                    enqueueSnackbar(
                      `please select an exercise in EMOM,  EMOM placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }
                  if (item.type == "amrap") {
                    enqueueSnackbar(
                      `please select an exercise in AMRAP, AMRAP placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }

                  isError = true;

                  return;
                }
                let data = {
                  exercise_info: {
                    _id: item2.exercise?._id,
                    title: item2.exercise?.title,
                    image: item2.exercise?.image,
                  },
                  title: item2.exercise?.title,
                  description: item2.description,
                  parameters: item2.parameters,
                };
                if (item2.set) {
                  data["sets"] = [];
                  if (item2.set.length > 0) {
                    data["sets"] = item2.set.map((item3) => {
                      let data = {};
                      if (item3.race_type) {
                        data["race_type"] = item3.race_type;
                      }
                      if (item3.pace) {
                        data["pace_low"] = item3.pace;
                      }
                      if (item3.mhr) {
                        data["mhr"] = item3.mhr;
                      }
                      if (item3.reps) {
                        data["reps"] = item3.reps;
                      }
                      if (item3.rest) {
                        data["rest"] = item3.rest;
                      }
                      if (item3.weight) {
                        data["weight"] = item3.weight;
                      }
                      if (item3.time) {
                        data["duration"] = item3.time;
                      }
                      if (item3.weightType) {
                        data["weight_unit"] = item3.weightType;
                      }
                      if (item3.distance) {
                        data["distance"] = item3.distance;
                      }
                      if (item3.distanceType) {
                        data["distance_unit"] = item3.distanceType;
                      }
                      if (item3.timeType) {
                        data["duration_unit"] = item3.timeType;
                      }
                      if (item3.restType) {
                        data["rest_unit"] = item3.restType;
                      }
                      if (item3.tempo) {
                        data["tempo"] = item3.tempo;
                      }
                      return data;
                    });
                  }
                }

                return data;
              });
            } else {
              if (item.type == "emom") {
                enqueueSnackbar(
                  `please add a Movement on ${
                    ind + 1
                  } Interval in EMOM, EMOM placed on ${mainIn + 1} position.`,
                  {
                    variant: "error",
                  }
                );
              }
              if (item.type == "amrap") {
                enqueueSnackbar(
                  `please add a Movement in AMRAP,  placed on ${
                    mainIn + 1
                  } position.`,
                  {
                    variant: "error",
                  }
                );
              }

              isError = true;

              return;
            }
          });
        }
      }
      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            if (isError) {
              return;
            }
            if (!item.exercise?._id) {
              enqueueSnackbar(
                `please select an exercise in Superset,  placed on ${
                  mainIn + 1
                } position.`,
                {
                  variant: "error",
                }
              );

              isError = true;
              return;
            }
            let data = {
              exercise_info: {
                _id: item.exercise?._id,
                title: item.exercise?.title,
                image: item.exercise?.image,
              },
              title: item.exercise?.title,
              description: item.description,
              parameters: item.parameters,
            };
            if (item.set) {
              data["sets"] = [];
              if (item.set.length > 0) {
                data["sets"] = item.set.map((item) => {
                  let data = {};
                  if (item.race_type) {
                    data["race_type"] = item.race_type;
                  }
                  if (item.pace) {
                    data["pace_low"] = item.pace;
                  }
                  if (item.mhr) {
                    data["mhr"] = item.mhr;
                  }
                  if (item.reps) {
                    data["reps"] = item.reps;
                  }
                  if (item.rest) {
                    data["rest"] = item.rest;
                  }
                  if (item.weight) {
                    data["weight"] = item.weight;
                  }
                  if (item.time) {
                    data["duration"] = item.time;
                  }
                  if (item.weightType) {
                    data["weight_unit"] = item.weightType;
                  }
                  if (item.distance) {
                    data["distance"] = item.distance;
                  }
                  if (item.distanceType) {
                    data["distance_unit"] = item.distanceType;
                  }
                  if (item.timeType) {
                    data["duration_unit"] = item.timeType;
                  }
                  if (item.restType) {
                    data["rest_unit"] = item.restType;
                  }
                  if (item.tempo) {
                    data["tempo"] = item.tempo;
                  }
                  return data;
                });
              }
            }

            return data;
          });
        }
      }
      return data;
    });
    if (isError) {
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("title", workoutTitle);

    // formData.append("image", state.image);
    if (imageStatus) {
      formData.append("image", file);
    }
    if (imageStatus) {
      formData.append("video_thumbnail", inputs.image);
    }

    formData.append("video_url", inputs.video_url);
    formData.append("type", state.type);
    formData.append("description", workoutDescription);
    formData.append("status", state.status);
    formData.append(
      "difficulty_levels",
      JSON.stringify(state?.difficulty_levels)
    );
    formData.append("exercises", JSON.stringify(exerciesArray));
    formData.append("workout_note", inputs.workout_note);
    console.log(exerciesArray, "dskjfhakjdsh");
    console.log(...formData, "dskjfhakjdsh");

    const result = await EditWorkoutApi(formData, state.workout_slug);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // getExerciseList();
  }, [serchText]);
  useEffect(() => {
    setInputs({
      ...inputs,
      ["video_url"]: state?.video_url,
      ["workout_note"]: state?.workout_note,
    });

    setOldImage(state?.video_thumbnail?.thumbnail_1);
    setOldProfile(state?.image?.thumbnail_1);
    setExercise(state.exercises);
    let givenExercise = state.exercises.map((item) => {
      let SubSet = item?.sub_exercises?.map((item) => {
        return {
          _id: item?._id,
          exercise: item?.exercise_info,
          set: item?.sets.map((item1) => {
            return {
              mhr: item1?.mhr,
              time: item1?.duration,
              weight: item1?.weight,
              weightType: item1?.weight_unit,
              reps: item1?.reps,
              rest: item1?.rest,
              distance: item1?.distance,
              distanceType: item1?.distance_unit,
              timeType: item1?.duration_unit,
              restType: item1?.rest_unit,
              tempo: item1?.tempo,
              race_type: item1?.race_type ? item1?.race_type : "c",
              pace: item1?.pace_low ? item1?.pace_low : 0,
            };
          }),
          rounds: 10,
          emomSets: [[]],
          type: item.exercise_type ? item.exercise_type : "general",
          parameters: item?.parameters,
          description: item?.description,
          superSet: [],
        };
      });
      let emomAndAmrapSets = item?.emom_amrap?.map((item1, ind) => {
        let interval = item1?.map((item) => {
          return {
            _id: item?._id,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                mhr: item1?.mhr,
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace: item1?.pace_low ? item1?.pace_low : 0,
              };
            }),
            parameters: item?.parameters,
            description: item?.description,
            superSet: [],
          };
        });
        return interval;
      });
      return {
        _id: item?._id,
        exercise: item?.exercise_info,
        set: item?.sets.map((item1) => {
          return {
            time: item1?.duration,
            mhr: item1?.mhr,
            weight: item1?.weight,
            weightType: item1?.weight_unit,
            reps: item1?.reps,
            rest: item1?.rest,
            distance: item1?.distance,
            distanceType: item1?.distance_unit,
            timeType: item1?.duration_unit,
            restType: item1?.rest_unit,
            tempo: item1?.tempo,
            vdot: item1?.vdot ? item1?.vdot : null,
            race_type: item1?.race_type ? item1?.race_type : "c",
            pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
            pace: item1?.pace_low ? item1?.pace_low : 0,
            paceUp: item1?.pace_up ? item1?.pace_up : 423,
            optionsType:
              item1?.pace_options && item1?.pace_options.length > 0
                ? item1?.pace_options
                : [
                    { title: "1 KM Pace", key: "c_km" },
                    { title: "1 MILE Pace", key: "c_mi" },
                  ],
          };
        }),
        parameters: item?.parameters,
        rounds: item.emom_rounds ? item.emom_rounds : 10,
        title: item.title,
        type: item.exercise_type ? item.exercise_type : "general",
        time_interval: item.interval_duration,
        emomSets: emomAndAmrapSets?.length > 0 ? emomAndAmrapSets : [[]],
        description: item?.description,
        superSet: SubSet,
        superset_sets: item?.superset_sets,
      };
    });

    setaddArray(givenExercise);
  }, [state]);
  // console.log(exercise, "dfkjdshfja");
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(state, "");
  // console.log(addArray, "addArray");
  return (
    <div className="container">
      <div className="row mt-3 align-items-center">
        <div className="col-12 col-md-10">
          <div className="d-flex align-items-center">
            <IconButton
              className="back-screen-button mb-0"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <h1 className="programmes-heading ms-2">{state?.title}</h1>
          </div>
        </div>
        <div className="col-12 col-md-2 text-end">
          {/* <button
            className="small-contained-button "
            onClick={() => {
              handleOpen();
            }}
          >
            Add Admin Note
          </button> */}
        </div>
        <div className="col-12 section-space">
          <div
            dangerouslySetInnerHTML={{
              __html: state?.description,
            }}
          ></div>
        </div>
        <div className="row my-3">
          <div className="col-11 video-text">
            <p>
              The video URL and the thumbnail image are linked, as adding a
              video URL requires a thumbnail and vice versa. The thumbnail will
              appear on the calendar, and clicking it will open the popup.
            </p>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 ">
            <TextField
              id="outlined-basic"
              label="Video Url"
              variant="outlined"
              fullWidth
              // required
              name="video_url"
              value={inputs.video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            <div className="row w-100 div-style ms-0 p-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="mb-0">Upload Video Thumbnail </p>
                <FormHelperText className="pt-0" sx={{ fontSize: 9 }}>
                  Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className=" col-lg-2 col-md-2 col-sm-12">
                {file ? (
                  <img className="image-border" src={file} height="50" />
                ) : oldImage ? (
                  <img
                    className="image-border"
                    src={s3baseUrl + oldImage}
                    height="50"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12  text-lg-end text-md-end text-sm-start pt-2">
                <label htmlFor="contained-button-file">
                  <input
                    accept=".jpg, .jpeg, .png, .webp"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    required
                    onChange={fileChangedHandler}
                    style={{ display: "none" }}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                    variant="contained"
                    size="small"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
        </div>
        {/* <div className="row">
          <div className=" col-sm-12 col-md-10 col-lg-10 order-2 order-md-1">
            <FormControl
              className={!isFocused ? classes.customInput : ""}
              sx={{ width: "100%" }}
            >
              <TextField
                id="outlined-basic"
                // label="Exercise"
                variant={"outlined"}
                onFocus={handleFocus}
                className="title-Test"
                onBlur={handleBlur}
                placeholder="Workout Title"
                value={workoutTitle}
                name="workoutTitle"
                onChange={(e) => {
                  if (e.target.value.length <= 30)
                    setWorkoutTitle(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "18px" },
                }}
                InputProps={{
                  style: {
                    fontSize: "32px",
                    fontWeight: "500",
                  },
                }}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },

                  fontSize: "13px",
                  width: "100%",
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={!isFocused1 ? classes.customInput : ""}
            >
              <TextField
                id="outlined-basic"
                className="desc-Test"
                // label="Exercise"
                variant={"outlined"}
                onFocus={handleFocus1}
                onBlur={handleBlur1}
                placeholder="Workout description"
                value={workoutDescription}
                multiline
                rows={3}
                name="workoutTitle"
                onChange={(e) => {
                  if (e.target.value.length <= 250)
                    setWorkoutDescription(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "18px" },
                }}
                InputProps={{
                  style: {
                    fontSize: "14px",
                    fontWeight: "500",
                    padding: 0,
                  },
                  maxLength: 10,
                }}
                sx={{
                  borderRadius: "5px",

                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  mb: 1,
                  width: "100%",
                }}
              />
            </FormControl>
          </div>
          <div className="col-sm-12 col-md-2 col-lg-2 order-1 order-md-2 d-flex justify-content-center">
            <div className="edit-workout-icon ">
              <Avatar
                alt={"I"}
                variant="square"
                src={previews ? previews : s3baseUrl + oldProfile}
                sx={{ width: 120, height: 120 }}
              >
                <div className="fs-1">{workoutTitle.charAt(0)}</div>
              </Avatar>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  name="profile_image"
                  onChange={(e) => handleUpload(e)}
                />
                <IconButton
                  className="edit-profile-icon-position"
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                ></IconButton>
              </label>

              <span
                // className="small-contained-button"
                style={{
                  position: "absolute",
                  bottom: "36%",
                  right: "26%",
                  // backgroundColor: "gray",
                  // borderRadius: "50%",
                  // padding: "4px 5px",
                }}
              >
                <PhotoCamera
                  sx={{ width: 55, height: 55 }}
                  className="pointer camera-Workout"
                  // ref={anchorRef}
                  onClick={() => myInputRef.current.click()}
                />
                <input
                  hidden
                  type="file"
                  ref={myInputRef}
                  onChange={handleUpload}
                />
              </span>
            </div>
          </div>
        </div> */}

        <div>
          {addArray.length > 0 ? (
            <>
              <FoodMattersAndMindsetUI
                addArray={addArray}
                setaddArray={setaddArray}
                setModalChangeExe={() => {}}
                mainindex={0}
              />
              <div className="text-end mt-4">
                <button
                  className="small-contained-button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </>
          ) : (
            <div className="text-end container my-4">
              <button
                className="small-contained-button "
                onClick={() => {
                  handleAdd();
                }}
              >
                Add Exercise
              </button>
            </div>
          )}
        </div>
      </div>
      <NotePopup
        inputs={inputs}
        setInputs={setInputs}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};
export default EditMindset;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import ModalToShowAninfo from "./ModalToShowAninfo";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import DisplaySet from "./DisplaySet";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  // Add your custom styles here
  backdrop: {
    backgroundColor: "white", // Change the color as per your requirement
  },
}));
export default function AlertDialogSlide({
  open,
  setOpen,
  exercise,
  isFullScreen,
  setIsFullScreen,
  toggleFullScreen,
}) {
  const [openSingleExerciseInfo, setOpenSingleExerciseInfo] =
    React.useState(false);
  const [singleExerciseInfo, setSingleExerciseInfo] = React.useState("");
  const classes = useStyles();
  const scrollableRef = useRef(null);

  const [zoomLevel, setZoomLevel] = useState(100);

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleClickOpen = (exercise) => {
    setSingleExerciseInfo(exercise);
    setOpenSingleExerciseInfo(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 10);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(10, prevZoom - 10));
  };

  const handleKeyDown = (e) => {
    if (scrollableRef.current.scrollBy) {
      if (e.key === "ArrowDown") {
        scrollableRef.current.scrollBy({ top: 100, behavior: "smooth" });
      } else if (e.key === "ArrowUp") {
        scrollableRef.current.scrollBy({ top: -100, behavior: "smooth" });
      }
    }
  };

  const getMinutesForInterval = (index) => {
    console.log(
      exercise.interval_duration,
      index,
      "dsflaksdjfkljkas",
      exercise
    );
    const interval = exercise.interval_duration;
    if (interval <= 60) {
      return index + 1;
    }
    if (interval % 60 == 0) {
      return `${
        (interval / 60) * index +
        1 +
        " – " +
        ((interval / 60) * index + interval / 60)
      }`;
    }
    let startInterval = interval * index;
    let endtInterval = interval * index + interval;
    let startintervalText =
      interval * index
        ? Math.floor(startInterval / 60) +
          " " +
          ":" +
          formatWithLeadingZeros(startInterval % 60)
        : 0;
    let endIntervalText =
      Math.floor(endtInterval / 60) +
      " " +
      ":" +
      formatWithLeadingZeros(endtInterval % 60);

    return `${startintervalText + " – " + endIntervalText}`;
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isNowFullScreen = !!document.fullscreenElement;
      setIsFullScreen(isNowFullScreen);
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  console.log(exercise, "exerciseexerciseexercise");

  return (
    <React.Fragment>
      <Dialog
        onKeyDown={handleKeyDown}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="lg"
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            height: "100%",
          },
        }}
        disableEnforceFocus={true}
      >
        <ModalToShowAninfo
          open={openSingleExerciseInfo}
          setOpen={setOpenSingleExerciseInfo}
          exercise={singleExerciseInfo}
        />
        <div className="text-end mt-1 d-flex justify-content-end align-items-center">
          <IconButton className="back-screen-button" onClick={toggleFullScreen}>
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <IconButton className="back-screen-button" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent ref={scrollableRef}>
          <DialogContentText id="alert-dialog-slide-description">
            <Card
              key={exercise.id}
              sx={{
                height: "100%",
                zoom: `${zoomLevel}%`,
              }}
              className="base-card"
            >
              <CardContent sx={{ padding: "8px" }}>
                <div key={exercise.id} className="text-start">
                  {exercise.exercise_type === "something_else" && (
                    <>
                      <div
                        className="text-center"
                        style={{
                          color: "#ff7f00",
                          marginBottom: "20px",
                        }}
                      >
                        <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                        Something Else
                      </div>

                      <Card className="exercise-card mb-1 d-flex align-items-center">
                        <div className="mt-3 something_else_description mx-3">
                          <div className="custom_description_title">
                            Description
                          </div>
                          <div className="custom_description_text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: exercise.description,
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    </>
                  )}

                  {exercise.exercise_type === "amrap" &&
                    exercise.emom_amrap &&
                    exercise.emom_amrap.length > 0 && (
                      <div>
                        <div
                          className="text-center"
                          style={{ color: "#ff7f00" }}
                        >
                          <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                          AMRAP
                        </div>
                        {exercise.emom_amrap.map((interval, intervalIndex) => (
                          <div key={intervalIndex}>
                            <div
                              style={{
                                fontSize: "11px",
                                color: "#ff7f00",
                              }}
                            >
                              Movements
                            </div>
                            {interval.map((exerData) => (
                              <div key={exerData.id}>
                                <Card className="exercise-card my-1 ">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Icon
                                        icon="ph:tag-simple-fill"
                                        className="ms-1"
                                      />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <div className="px-2">
                                            <Typography
                                              variant="subtitle2"
                                              component="div"
                                            >
                                              {exerData.exercise_info.title}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleClickOpen(exerData)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: get_root_value(
                                              "--portal-theme-primary"
                                            ),
                                          }}
                                        >
                                          <Icon icon="octicon:info-24" />
                                        </div>
                                      </div>
                                      <div className="px-2">
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 10 }}
                                        >
                                          <DisplaySet
                                            sets_array={exerData?.sets}
                                            parameters={exerData?.parameters}
                                          />
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  {exercise.exercise_type === "rounds_for_time" &&
                    exercise.emom_amrap &&
                    exercise.emom_amrap.length > 0 && (
                      <div>
                        <div
                          className="text-center"
                          style={{ color: "#ff7f00" }}
                        >
                          <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                          Rounds For Time
                        </div>
                        {exercise.emom_amrap.map((interval, intervalIndex) => (
                          <div key={intervalIndex}>
                            <div
                              style={{
                                fontSize: "11px",
                                color: "#ff7f00",
                              }}
                            >
                              Movements
                            </div>
                            {interval.map((exerData) => (
                              <div key={exerData.id}>
                                <Card className="exercise-card my-1 ">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Icon
                                        icon="ph:tag-simple-fill"
                                        className="ms-1"
                                      />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <div className="px-2">
                                            <Typography
                                              variant="subtitle2"
                                              component="div"
                                            >
                                              {exerData.exercise_info.title}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleClickOpen(exerData)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: get_root_value(
                                              "--portal-theme-primary"
                                            ),
                                          }}
                                        >
                                          <Icon icon="octicon:info-24" />
                                        </div>
                                      </div>
                                      <div className="px-2">
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 10 }}
                                        >
                                          <DisplaySet
                                            sets_array={exerData?.sets}
                                            parameters={exerData?.parameters}
                                          />
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}

                  {exercise.exercise_type === "reps_for_time" &&
                    exercise.emom_amrap &&
                    exercise.emom_amrap.length > 0 && (
                      <div>
                        <div
                          className="text-center"
                          style={{ color: "#ff7f00" }}
                        >
                          <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                          Reps For Time
                        </div>
                        {exercise.emom_amrap.map((interval, intervalIndex) => (
                          <div key={intervalIndex}>
                            <div
                              style={{
                                fontSize: "11px",
                                color: "#ff7f00",
                              }}
                            >
                              Movements
                            </div>
                            {interval.map((exerData) => (
                              <div key={exerData.id}>
                                <Card className="exercise-card my-1 ">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Icon
                                        icon="ph:tag-simple-fill"
                                        className="ms-1"
                                      />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <div className="px-2">
                                            <Typography
                                              variant="subtitle2"
                                              component="div"
                                            >
                                              {exerData.exercise_info.title}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleClickOpen(exerData)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: get_root_value(
                                              "--portal-theme-primary"
                                            ),
                                          }}
                                        >
                                          <Icon icon="octicon:info-24" />
                                        </div>
                                      </div>
                                      <div className="px-2">
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 10 }}
                                        >
                                          <DisplaySet
                                            type={"reps_for_time"}
                                            sets_array={exerData?.sets}
                                            parameters={exerData?.parameters}
                                          />
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}

                  {exercise.exercise_type === "emom" &&
                    exercise.emom_amrap &&
                    exercise.emom_amrap.length > 0 && (
                      <div>
                        <h4
                          className="text-center"
                          style={{ color: "#ff7f00" }}
                        >
                          <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                          EMOM
                        </h4>
                        {exercise?.emom_amrap?.map(
                          (interval, intervalIndex) => {
                            return (
                              <div className="my-1">
                                <div
                                  style={{
                                    fontSize: "11px",
                                    color: "#ff7f00",
                                  }}
                                >
                                  Minute {getMinutesForInterval(intervalIndex)}
                                </div>
                                {interval?.map((exerData) => {
                                  console.log(exerData, "skdfjhdskja");
                                  return (
                                    <div key={exerData.id}>
                                      <Card className="exercise-card my-1 ">
                                        <div className="d-flex align-items-center">
                                          <div>
                                            <Icon
                                              icon="ph:tag-simple-fill"
                                              className="ms-1"
                                            />
                                          </div>
                                          <div>
                                            <div className="d-flex align-items-center">
                                              <div>
                                                <div className="px-2">
                                                  <Typography
                                                    variant="subtitle2"
                                                    component="div"
                                                  >
                                                    {
                                                      exerData.exercise_info
                                                        .title
                                                    }
                                                  </Typography>
                                                </div>
                                              </div>
                                              <div
                                                onClick={() =>
                                                  handleClickOpen(exerData)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: get_root_value(
                                                    "--portal-theme-primary"
                                                  ),
                                                }}
                                              >
                                                <Icon icon="octicon:info-24" />
                                              </div>
                                            </div>
                                            <div className="px-2">
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ fontSize: 10 }}
                                              >
                                                <DisplaySet
                                                  sets_array={exerData?.sets}
                                                  parameters={
                                                    exerData?.parameters
                                                  }
                                                />
                                              </Typography>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  {exercise.sub_exercises && exercise.sub_exercises.length > 0 && (
                    <div>
                      <div
                        className="text-center"
                        style={{
                          color: "#ff7f00",
                          marginBottom: "20px",
                        }}
                      >
                        <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                        Superset
                      </div>
                      {exercise.sub_exercises.map((exerData) => (
                        <div key={exerData.id}>
                          <Card className="exercise-card my-1 ">
                            <div className="d-flex align-items-center">
                              <div>
                                <Icon
                                  icon="ph:tag-simple-fill"
                                  className="ms-1"
                                />
                              </div>
                              <div>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="px-2">
                                      <Typography
                                        variant="subtitle2"
                                        component="div"
                                        className="d-flex"
                                      >
                                        {exerData.exercise_info.title}
                                        {exerData?.sets?.length > 1 && (
                                          <div
                                            className="ms-2 detail_open_icon"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              toggleDetails();
                                            }}
                                          >
                                            {showDetails ? (
                                              <>
                                                <ExpandLessIcon /> {"Sets"}{" "}
                                                {exerData?.sets?.length}
                                              </>
                                            ) : (
                                              <>
                                                <ExpandMoreIcon /> {"Sets"}{" "}
                                                {exerData?.sets?.length}
                                              </>
                                            )}
                                          </div>
                                        )}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => handleClickOpen(exerData)}
                                    style={{
                                      cursor: "pointer",
                                      color: get_root_value(
                                        "--portal-theme-primary"
                                      ),
                                    }}
                                  >
                                    <Icon icon="octicon:info-24" />
                                  </div>
                                </div>
                                <div className="px-2">
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: 10 }}
                                  >
                                    <DisplaySet
                                      sets_array={
                                        showDetails
                                          ? exerData?.sets
                                          : [exerData?.sets[0]]
                                      }
                                      parameters={exerData?.parameters}
                                    />
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      ))}
                    </div>
                  )}
                  {exercise.exercise_type == "general" &&
                    exercise.sub_exercises.length == 0 && (
                      <>
                        <div
                          className="text-center"
                          style={{
                            color: "#ff7f00",
                            marginBottom: "20px",
                          }}
                        >
                          <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                          General Exercise
                        </div>
                        <Card className="exercise-card my-1 ">
                          <div className="d-flex align-items-center">
                            <div>
                              <Icon
                                icon="ph:tag-simple-fill"
                                className="ms-1"
                              />
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="px-2">
                                    <Typography
                                      variant="subtitle2"
                                      component="div"
                                      className="d-flex"
                                    >
                                      {exercise.exercise_info.title}
                                      {exercise?.sets?.length > 1 && (
                                        <div
                                          className="ms-2 detail_open_icon"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            toggleDetails();
                                          }}
                                        >
                                          {showDetails ? (
                                            <>
                                              <ExpandLessIcon /> {"Sets"}{" "}
                                              {exercise?.sets?.length}
                                            </>
                                          ) : (
                                            <>
                                              <ExpandMoreIcon /> {"Sets"}{" "}
                                              {exercise?.sets?.length}
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  onClick={() => handleClickOpen(exercise)}
                                  style={{
                                    cursor: "pointer",
                                    color: get_root_value(
                                      "--portal-theme-primary"
                                    ),
                                  }}
                                >
                                  <Icon icon="octicon:info-24" />
                                </div>
                              </div>
                              <div className="px-2">
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: 10 }}
                                >
                                  <DisplaySet
                                    sets_array={
                                      showDetails
                                        ? exercise?.sets
                                        : [exercise?.sets[0]]
                                    }
                                    parameters={exercise?.parameters}
                                  />
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </>
                    )}
                </div>
              </CardContent>
            </Card>
          </DialogContentText>
        </DialogContent>
        <div className="zoom-controls">
          <div className="me-2 cross-icon">
            <RemoveCircleIcon
              onClick={handleZoomOut}
              className="diary_icon_plus_minus"
            />
          </div>
          <Typography variant="body1" sx={{ fontWeight: 500, color: "#fff" }}>
            Zoom
          </Typography>
          <div className="cross-icon ms-2">
            <AddCircleIcon
              onClick={handleZoomIn}
              className="diary_icon_plus_minus"
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

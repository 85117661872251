import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "@mui/material/Tooltip";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import {
  delete_event_api,
  new_event_list_api,
} from "src/DAL/Calender/Calender";
import DayCalendar from "./DayCalendar";
import WeekCalendar from "./WeekCalendar";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import FullPagePopup from "src/components/FormsDialog/FullPagePopup";
import AddCalendarEvents from "./AddCalendarEvents";
import UpdateCalendarEvent from "./UpdateCalendarEvent";
import GoogleAuth from "./GoogleAuth";
import EventDetailNew from "./EventDetailNew";
import DetailPopUpModelNew from "src/components/Calender/DetailPopUpModelNew";
import PerformActionOn from "./PerformActionOn";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const handle_get_month = (date) => {
  let startOfMonth = moment(date).startOf("month");
  let dayOfWeek = startOfMonth.day();
  let previousSunday = startOfMonth.subtract(dayOfWeek, "days");
  let previousSundayDate = previousSunday.toDate();

  let endMonth = moment(date).endOf("month");
  let endMonthDays = Math.abs(endMonth.day() - 0 - 6);
  let nextSunday = endMonth.add(endMonthDays, "days");
  let nextSaturdayDate = nextSunday.toDate();
  let currentMonth = startOfMonth.add(7, "days");

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
    currentMonth: currentMonth,
  };
};

const get_view_dates = (tool) => {
  if (tool == "month") {
    return handle_get_month(new Date());
  }
  if (tool == "day") {
    return {
      start_date: new Date(),
      end_date: new Date(),
    };
  }

  let startOfMonth = moment().startOf(tool);
  let daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  let previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let nextSaturday = startOfMonth.add(7, "days");
  let nextSaturdayDate = nextSaturday.toDate();

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};

export const handle_upgrade_month = (date) => {
  date = moment(date).add(1, "month").toDate();
  return handle_get_month(date);
};

export const handle_downgrade_month = (date) => {
  date = moment(date).subtract(1, "month").toDate();
  return handle_get_month(date);
};

function CalendarEvents() {
  const classes = useStyles();
  const { setUserInfo, googleClientId } = useContentSetting();
  const calendarRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventDetailData, setEventDetailData] = useState([]);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedTool, setSelectedTool] = useState("month");
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [openDelete, setOpenDelete] = useState(false);

  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleUpdate = () => {
    handleOpenEditDrawer();
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const eventDetail = (event) => {
    setEventDetailData(event);
    setPopupState(true);
  };

  const handleAgreeDelete = () => {
    setPopupState(false);
    setOpenDelete(true);
  };

  const handleDelete = async (value) => {
    setOpenDelete(false);
    let postData = {
      event_slug: eventDetailData.event_slug,
      iteration_id: eventDetailData._id,
      update_type: value,
    };
    const result = await delete_event_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getEventListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const renderEventContent = (eventInfo) => {
    let check = eventInfo.event._def.extendedProps.completion_status;
    return (
      <Tooltip title={`${check ? "✔" : ""} ${eventInfo.event.title}`}>
        <div
          className="d-flex"
          onClick={() => eventDetail(eventInfo.event._def.extendedProps)}
        >
          <div
            className="event-title-dot"
            style={{
              backgroundColor: eventInfo.event._def.extendedProps.event_color,
            }}
          ></div>
          <div className="calendar-event-title"></div>
          <div className="calendar-event-title">
            <span className="start_time_for_popup">
              {eventInfo.event._def.extendedProps.start_time_for_popup}
            </span>
            &nbsp;&nbsp;
            {eventInfo.event.title.length > 15
              ? `${check ? "✔" : ""} ${eventInfo.event.title.substring(
                  0,
                  15
                )}...`
              : `${check ? "✔" : ""} ${eventInfo.event.title}`}
          </div>
        </div>
      </Tooltip>
    );
  };

  const getEventListing = async () => {
    if (selectedTool !== "month") {
      setIsLoading(true);
    }
    let postData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
      default_caleder_value: selectedTool,
    };
    const result = await new_event_list_api(postData);
    if (result.code === 200) {
      let all_events = [];
      // moment.tz.setDefault(result.time_zone);
      if (result.event.length > 0) {
        // .filter(
        //   (filter_event) =>
        //     filter_event.action_by == "member_user" ||
        //     filter_event.status == true
        // )
        all_events = result.event.map((event) => {
          return {
            ...event,
            title: event.title,
            color: event.color,
            event_color: event.color,
            event_title: event.title,
            recurring_type: event.recurring_type,
            weekday: event.weekday,
            status: event.status,
            date: event.start_date_time,
            start_date: moment(event.start_date_time).format("YYYY-MM-DD"),
            end_date: moment(event.end_date_time).format("YYYY-MM-DD"),
            start: event.start_date_time,
            end: event.end_date_time,
            start_time: moment(event.start_date_time).utc().format("hh:mm A"),
            start_time_for_popup: moment(event.start_date_time)
              .utc()
              .format("hh:mm A"),
            end_time: moment(event.end_date_time).utc().format("hh:mm A"),
          };
        });
      }
      setEvents(all_events);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const handleChangeTools = (tool) => {
    setCurrentMonth(new Date());
    setUserInfo((old) => {
      return {
        ...old,
        default_caleder_value: tool,
      };
    });
    let dates = get_dates(tool);
    setCurrentDate(dates);
    setSelectedTool(tool);
  };

  const goToToday = () => {
    if (calendarRef?.current) {
      const calendarApi = calendarRef.current.getApi();
      const today = new Date();

      calendarApi.gotoDate(today);
      const newDateRange = {
        start_date: calendarApi.view.activeStart,
        end_date: calendarApi.view.activeEnd,
      };
      setCurrentDate(newDateRange);
      setTimeout(() => {
        const todayDate = today.toISOString().slice(0, 10);
        const todayCell = document.querySelector(
          `.fc-day[data-date="${todayDate}"]`
        );
        if (todayCell) {
          todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 1000);
    }
  };

  const getCenteredText = () => {
    let text = "";
    if (selectedTool == "month") {
      text = moment(currentMonth).format("MMM YYYY");
    } else if (selectedTool == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value == "next") {
      if (selectedTool == "month") {
        let data = handle_upgrade_month(currentMonth);
        new_object = {
          start_date: data.start_date,
          end_date: data.end_date,
        };
        setCurrentMonth(data.currentMonth);
      } else {
        new_object = {
          start_date: moment(start_date)
            .add(1, selectedTool)
            .startOf(selectedTool)
            .toDate(),
          end_date: moment(end_date)
            .add(1, selectedTool)
            .endOf(selectedTool)
            .toDate(),
        };
      }
    } else {
      if (selectedTool == "month") {
        let data = handle_downgrade_month(currentMonth);
        new_object = {
          start_date: data.start_date,
          end_date: data.end_date,
        };
        setCurrentMonth(data.currentMonth);
      } else {
        new_object = {
          start_date: moment(start_date)
            .subtract(1, selectedTool)
            .startOf(selectedTool)
            .toDate(),
          end_date: moment(end_date)
            .subtract(1, selectedTool)
            .endOf(selectedTool)
            .toDate(),
        };
      }
    }
    setCurrentDate(new_object);
  };

  const handleClickDate = (event) => {
    setSelectedTool("day");

    setCurrentDate({
      start_date: event.dateStr,
      end_date: event.dateStr,
    });
  };

  const handleChangeNextPrev = (value) => {
    if (selectedTool == "month") {
      let classToClick = "fc-next-button";
      if (value == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }

    hanldeChangeDates(value);
  };

  useEffect(() => {
    getEventListing();
  }, [currentDate]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container my-calendar">
      <div className="row">
        <div className="col-4">
          <h2>My Calendar</h2>
        </div>
        <div className="col-8">
          <button
            className="small-contained-button float-end mt-1 mb-4"
            onClick={handleOpenDrawer}
          >
            Add Event
          </button>
          {googleClientId?.client_id && googleClientId?.is_calendar_enabled && (
            <div className="float-end mt-1 mb-4 me-2">
              <GoogleAuth />
            </div>
          )}
        </div>
        <div className="col-6 order-2 col-md-4 full-calendar-toolbar">
          <div class="btn-group toollbar-group-buttons">
            <button
              className={`small-contained-button`}
              onClick={() => {
                handleChangeNextPrev("prev");
              }}
            >
              <ArrowBackIosIcon />
            </button>
            <button
              className={`small-contained-button`}
              onClick={() => {
                handleChangeNextPrev("next");
              }}
            >
              <ArrowForwardIosIcon />
            </button>
          </div>
        </div>
        <div className="col-12 col-md-4 order-1 order-md-2 mb-4 mb-md-0 text-center full-calendar-toolbar">
          <h2>{getCenteredText()}</h2>
        </div>
        <div className="col-6 col-md-4 order-3 text-center text-md-end">
          <div class="btn-group toollbar-group-buttons">
            <div className="group-buttons-font-size-on-sm">
              <button
                className={`small-contained-outlined-button`}
                onClick={() => goToToday()}
              >
                Today
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-12 full-calendar full-calendar-new-flow mt-3 order-4">
          {selectedTool == "month" ? (
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "today prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
              eventContent={renderEventContent}
              events={events}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={window.screen.width > 768 ? 2 : 1}
              weekends={true}
              fixedWeekCount={false}
              moreLinkContent={function (args) {
                return "+" + args.num;
              }}
              dayPopoverFormat={{
                month: "long",
                year: "numeric",
                day: "numeric",
                weekday: "long",
              }}
            />
          ) : selectedTool == "week" ? (
            <WeekCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
            />
          ) : (
            <DayCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
            />
          )}
        </div>
      </div>
      <DetailPopUpModelNew
        open={popupState}
        setOpen={setPopupState}
        handleAgreeDelete={handleAgreeDelete}
        handleEdit={handleUpdate}
        title={"Event Detail"}
        eventDetailData={eventDetailData}
        componentToPassDown={
          <EventDetailNew
            setPopupState={setPopupState}
            setIsLoading={setIsLoading}
            eventDetailData={eventDetailData}
            setEventDetailData={setEventDetailData}
            dataList={getEventListing}
            onCloseDrawer={handleCloseEditDrawer}
            setEditDrawerState={setEditDrawerState}
          />
        }
      />
      <PerformActionOn
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Delete recurring event?"}
        handleAgree={handleDelete}
      />
      <FullPagePopup
        open={editDrawerState}
        setOpen={setEditDrawerState}
        componentToPassDown={
          <UpdateCalendarEvent
            editValues={eventDetailData}
            dataList={getEventListing}
            handleCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
      <FullPagePopup
        open={drawerState}
        setOpen={setDrawerState}
        componentToPassDown={
          <AddCalendarEvents
            dataList={getEventListing}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}

export default CalendarEvents;
